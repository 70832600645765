import React, { useEffect, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';
import { API_URL } from '../utils/urls';
import axios, { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';

export const AuthContext = React.createContext();

const UseProvideAuth = () => {
  const [user, setUser] = useState(null);
  const [merchants, setMerchants] = useState([]);
  const { getItem, setItem, removeItem } = useLocalStorage();
  const [isLoading, setIsLoading] = useState(false);
  const [authError, setAuthError] = useState(null);

  useEffect(() => {
    const addUser = async () => {
      setIsLoading(true);
      const authData = getItem('auth');
      if (authData) {
        await _addUser(authData);
      }
      setIsLoading(false);
    };
    addUser();
  }, []);

  useEffect(() => {
    const getMerchants = async () => {
      try {
        let endpoint = 'merchant';
        const accessToken = getItem('auth');

        if (user['roles'].includes('super_admin')) {
          endpoint = 'merchant/all';
        }

        const url = `${API_URL}/${endpoint}`;

        const splitedURL = API_URL.split('/');
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': `${splitedURL[0]}//${splitedURL[2]}`,
        };
        const res = await axios.get(url, {
          headers,
          withCredentials: true,
        });
        if (res.status === 200) {
          const { data } = res;
          if (!user['roles'].includes('super_admin')) {
            setMerchants([data]);
          } else {
            setMerchants(data);
          }
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err?.response?.status === 401) {
            await refreshTokenOrLogout();
          }
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (user) getMerchants();
    return () => {};
  }, [user]);

  const _addUser = async (auth) => {
    const userInfo = jwtDecode(auth);
    const now = new Date();
    if (now.getTime() > userInfo.exp * 1000) {
      await refreshTokenOrLogout();
      return;
    }
    setUser(userInfo.user);
    setItem('auth', auth);
  };

  const _removeUser = () => {
    setUser(null);
    removeItem('auth');
  };

  const getAccessToken = () => {
    return getItem('auth');
  };

  const login = async (inputs) => {
    const splitedURL = API_URL.split('/');
    const myHeaders = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': `${splitedURL[0]}//${splitedURL[2]}`,
    };

    const { email, password } = inputs;
    const formData = {
      email,
      password,
    };
    const requestOptions = {
      headers: myHeaders,
      body: JSON.stringify(formData),
      redirect: 'follow',
      withCredentials: true,
    };
    setIsLoading(true);
    const url = `${API_URL}/authentication/login`;
    try {
      const res = await axios.post(
        url,
        JSON.stringify(formData),
        requestOptions
      );
      if (res.status === 200) {
        const body = res.data;
        setAuthError(null);
        _addUser(body.auth);
      } else {
        if (res.status === 400) {
          setAuthError('Error en las credenciales');
        }
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        if (err?.response?.status === 400) {
          setAuthError('Error en las credenciales');
        } else {
          setAuthError('Error en la conexión con el servidor');
        }
      } else {
        setAuthError('Error en la conexión con el servidor');
      }
    }
    setIsLoading(false);
  };

  const refreshTokenOrLogout = async () => {
    const splitedURL = API_URL.split('/');
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': `${splitedURL[0]}//${splitedURL[2]}`,
    };

    const endpoint = `${API_URL}/authentication/refresh`;
    try {
      const res = await axios({
        url: endpoint,
        method: 'GET',
        data: {},
        headers,
        withCredentials: true,
      });
      if (res.status === 200) {
        const { data } = res;
        await _addUser(data.auth);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        await logout();
      }
    }
  };

  const logout = async () => {
    const accessToken = getItem('auth');
    const splitedURL = API_URL.split('/');
    const endpoint = `${API_URL}/authentication/logout`;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': `${splitedURL[0]}//${splitedURL[2]}`,
    };
    try {
      await axios({
        url: endpoint,
        method: 'POST',
        data: {},
        headers,
        withCredentials: true,
      });
    } catch (err) {
      console.log('Logged out for expired session.');
    } finally {
      _removeUser();
    }
  };

  return {
    user,
    merchants,
    getAccessToken,
    refreshTokenOrLogout,
    login,
    logout,
    isLoading,
    authError,
  };
};

const AuthProvider = (props) => {
  const { children } = { ...props };
  const auth = UseProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
