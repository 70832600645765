const parseCategory = (category) => {
  const { name, id } = category;
  return {
    id,
    name,
  };
};

const parseCategoriesSelect = (apiData) => {
  const { data } = apiData;
  return data.map((category) => parseCategory(category));
};

export default parseCategoriesSelect;
