import React, { useEffect, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

export const MerchantContext = React.createContext();

const useProvideMerchant = () => {
  const [merchant, setMerchant] = useState(null);
  const { getItem, setItem } = useLocalStorage();

  useEffect(() => {
    const merchantId = getItem('merchant_id');
    if (merchantId === undefined) return;
    if (merchantId) {
      _addMerchant(merchantId);
    }
  }, []);

  const _addMerchant = (value) => {
    setMerchant(value);
    setItem('merchant_id', value);
  };

  const changeMerchant = (value) => {
    _addMerchant(value);
  };

  return {
    merchant,
    changeMerchant,
  };
};

const MerchantProvider = (props) => {
  const { children } = { ...props };
  const { merchant, changeMerchant } = useProvideMerchant();
  return (
    <MerchantContext.Provider
      value={{ value: merchant, setValue: changeMerchant }}
    >
      {children}
    </MerchantContext.Provider>
  );
};

export default MerchantProvider;
