export const rolesDict = {
  super_admin: 'Super Admin',
  admin: 'Administrador',
  caja: 'Caja',
};

const parseRole = (role) => {
  return role in rolesDict ? rolesDict[role] : role;
};

const parseDate = (date) => {
  const dateString = date.split('T')[0];
  const formatedDate = dateString.split('-').reverse().join('/');
  return formatedDate;
};

const parseUser = (user) => {
  const {
    id,
    email,
    roles,
    username,
    created_at: createdAt,
    merchant,
    active,
  } = user;

  return {
    id,
    email,
    roles,
    principalRole: parseRole(roles[0] || 'caja'),
    username,
    createdAt: parseDate(createdAt),
    merchantName: merchant?.name,
    active,
  };
};

const parseUsers = (apiData) => {
  const { data, metadata } = apiData;
  const headers = [
    { attrName: 'email', name: 'Correo' },
    { attrName: 'username', name: 'Usuario' },
    { attrName: 'principalRole', name: 'Rol principal' },
    { attrName: 'createdAt', name: 'Fecha de creación' },
    { attrName: 'merchantName', name: 'Comercio' },
    { attrName: 'clickAction', name: 'Acciones' },
  ];

  const parsedData = {
    headers,
    parsedData: data.map((transaction) => parseUser(transaction)),
  };

  return {
    parsedData,
    metadata: {
      current: parseInt(metadata.page || 0, 10),
      size: parseInt(metadata.size || 1, 10),
      count: Math.ceil(metadata.count / parseInt(metadata.size || 1, 10)),
    },
  };
};

export default parseUsers;
