import { useState } from 'react';
import POS from 'transbank-pos-sdk-web';
import PropType from 'prop-types';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Components
import ModalTemplate from '../layouts/ModalTemplate';

// Middlewares
import parsePOSActionResponse from '../../middlewares/parsers/Pos/parsePOSActionResponse';

const ModalPOSActionsMenu = (props) => {
  const { setShowModal, setShowCloseDayModal, setCloseModalType } = props;
  const [response, setResponse] = useState(null);
  const [pollWaiting, setPollWaiting] = useState(false);
  const [loadKeysWaiting, setLoadKeysWaiting] = useState(false);
  const [toNormalModeWaiting, setToNormalModeWaiting] = useState(false);
  const alertSwal = withReactContent(Swal);

  const loading = pollWaiting || loadKeysWaiting || toNormalModeWaiting;

  const poll = async (e) => {
    e.preventDefault();
    if (loading) return;

    setPollWaiting(true);
    setResponse(null);
    try {
      await POS.poll()
        .then((res) => {
          console.log(res);
          // this.$emit('onPollResponse', response)
          const parsedResponse = parsePOSActionResponse(res, 'Poll');
          setResponse(parsedResponse);
        })
        .finally(() => {
          setPollWaiting(false);
        });
    } catch (e) {
      console.log(e);
      alertSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const loadKeys = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoadKeysWaiting(true);
    setResponse(null);
    try {
      await POS.getKeys()
        .then((res) => {
          // this.$emit('onLoadKeyResponse', response);
          if (!res.successful) {
            alertSwal.fire({
              title: `Acción fallida (${res.responseCode})`,
              html: 'No se pudieron cargar nuevas llaves en el POS',
              icon: 'error',
            });
            setShowModal(false);
            return;
          }
          alertSwal.fire({
            title: `Llaves cargadas (${res.responseCode})`,
            html: 'Se cargaron nuevas llaves en el POS correctamente',
            icon: 'success',
          });
          setShowModal(false);
        })
        .finally(() => {
          setLoadKeysWaiting(false);
        });
    } catch (e) {
      console.log(e);
      alertSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const setNormalMode = async (e) => {
    e.preventDefault();
    if (loading) return;

    setToNormalModeWaiting(true);
    setResponse(null);
    try {
      await POS.setNormalMode()
        .then((res) => {
          console.log(res);
          // this.$emit('onNormalModeResponse', response)
          const parsedResponse = parsePOSActionResponse(res, 'NormalMode');
          setResponse(parsedResponse);
        })
        .finally(() => {
          setToNormalModeWaiting(false);
        });
    } catch (e) {
      console.log(e);
      alertSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const closeDay = (e) => {
    e.preventDefault();

    setCloseModalType('closeDay');
    setShowCloseDayModal(true);
    setShowModal(false);
  };

  const closePOSConnection = async (e) => {
    e.preventDefault();

    setCloseModalType('disconnect');
    setShowCloseDayModal(true);
    setShowModal(false);
  };

  return (
    <ModalTemplate
      title="Menu de acciones de POS Integrado"
      setShowModal={setShowModal}
    >
      <div className="p-6">
        <div className="flex flex-col gap-2">
          {/* Load eys */}
          <button
            disabled={loading}
            className="w-full btn-default px-5 py-2 shadow rounded text-white"
            onClick={loadKeys}
          >
            {loadKeysWaiting && <span>Cargando llaves...</span>}
            {!loadKeysWaiting && <span>Carga de llaves</span>}
          </button>
          {/* Close day */}
          <button
            disabled={loading}
            className="w-full btn-primary px-5 py-2 shadow rounded text-white"
            onClick={closeDay}
          >
            <span>Cerrar día</span>
          </button>
          {/* poll */}
          <button
            disabled={loading}
            className="w-full btn-warning px-5 py-2 shadow rounded text-white"
            onClick={poll}
          >
            {!pollWaiting && <span>Realizar Poll a POS</span>}
            {pollWaiting && <span>Haciendo Poll...</span>}
          </button>
          {/* Set normal mode */}
          <button
            disabled={loading}
            className="w-full btn-warning px-5 py-2 shadow rounded text-white"
            onClick={setNormalMode}
          >
            {!toNormalModeWaiting && <span>Salir de modo integrado</span>}
            {toNormalModeWaiting && <span>Saliendo...</span>}
          </button>
          {/* Close Connection */}
          <button
            disabled={loading}
            className="w-full btn-danger px-5 py-2 shadow rounded text-white"
            onClick={closePOSConnection}
          >
            <span>Desconectar POS</span>
          </button>
        </div>
        {/* Response */}
        <hr className="mt-4 mb-2" />
        {response !== null && (
          <div>
            <h2 className="text-lg">Mensaje: </h2>
            <p className="w-full break-words">{response.message || response}</p>
          </div>
        )}
      </div>
    </ModalTemplate>
  );
};

ModalPOSActionsMenu.propTypes = {
  setShowModal: PropType.func,
  setShowCloseDayModal: PropType.func,
  setCloseModalType: PropType.func,
};

export default ModalPOSActionsMenu;
