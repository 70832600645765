import POS from 'transbank-pos-sdk-web';
import { createContext, useEffect, useState } from 'react';

export const POSContext = createContext(null);

const useProvidePOS = () => {
  const [socketConnected, setSocketConnected] = useState(false);
  const [activePort, setActivePort] = useState(null);
  const [connected, setConnected] = useState(false);
  const [resultData, setResultData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const mount = () => {
      try {
        POS.on('socket_connected', () => {
          setSocketConnected(true);
        });

        POS.on('socket_disconnected', () => {
          setSocketConnected(false);
        });

        POS.on('port_opened', (port) => {
          setActivePort(port);
          setConnected(true);
        });

        POS.on('port_closed', () => {
          setActivePort(null);
          setConnected(false);
        });

        POS.connect();

        POS.getPortStatus().then((response) => {
          setConnected(response.connected);
          setActivePort(response.activePort);
        });
      } catch (e) {
        console.error('Conexión con el agente fallida: ', e);
        setError([
          'No se pudo conectar con el agente Transbank POS',
          'Verifique que el agente se haya inicializado en este computador',
          'error',
        ]);
      }
    };
    mount();
  }, []);

  const closeConnection = async () => {
    try {
      await POS.closePort();
      setActivePort(null);
      setConnected(false);
      return true;
    } catch {
      return false;
    }
  };

  return {
    socketConnected,
    activePort,
    connected,
    setConnected,
    closeConnection,
    resultData,
    setResultData,
    error,
  };
};

const POSProvider = (props) => {
  const { children } = { ...props };
  const {
    socketConnected,
    activePort,
    connected,
    setConnected,
    closeConnection,
    resultData,
    setResultData,
    error,
  } = useProvidePOS();
  return (
    <POSContext.Provider
      value={{
        socketConnected,
        activePort,
        connected,
        setConnected,
        closeConnection,
        resultData,
        setResultData,
        error,
      }}
    >
      {children}
    </POSContext.Provider>
  );
};

export default POSProvider;
