import { useState } from 'react';
import useAuth from './useAuth';
import { API_URL } from '../utils/urls';
import axios, { AxiosError } from 'axios';
import useMerchant from './useMerchant';

const usePosAPIAction = () => {
  const { user, getAccessToken, refreshTokenOrLogout, logout } = useAuth();
  const { selectedMerchant } = useMerchant();
  const [responseData, setResponseData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const executeAPIAction = async (
    endpoint,
    method = 'POST',
    data = {},
    retry = true,
    forceMerchant = false
  ) => {
    let success = false;

    setError(null);
    const accessToken = getAccessToken();

    const splitedURL = API_URL.split('/');
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
      'Access-Control-Allow-Origin': `${splitedURL[0]}//${splitedURL[2]}`,
    };

    setLoading(true);
    const url = `${API_URL}/${endpoint}`;
    try {
      const merchant_id =
        !forceMerchant && selectedMerchant && user.roles.includes('super_admin')
          ? selectedMerchant
          : data.merchant_id;

      const res = await axios({
        url,
        method,
        ...(Object.keys(data).length > 0
          ? { data: { ...data, merchant_id } }
          : {}),
        headers,
        withCredentials: true,
      });
      if (res.status >= 200 && res.status < 300) {
        setResponseData(res);
        success = true;
      }
    } catch (e) {
      setResponseData(null);
      if (e instanceof AxiosError) {
        if (e?.response?.status === 401 && retry) {
          await refreshTokenOrLogout();
          return await executeAPIAction(endpoint, method, data, false);
        }
        if (e?.response?.status === 401 && !retry) {
          await logout();
        }
        setError(e);
      } else {
        setError(e);
      }
    }
    setLoading(false);
    return success;
  };

  return {
    responseData,
    loading,
    error,
    setError,
    executeAPIAction,
  };
};

export default usePosAPIAction;
