import { useEffect, useState } from 'react';
import Input from '../Shared/Input';
import Chip from '../Shared/Chip';
import Spinner from '../Shared/Spinner';
import TableComponent from '../Shared/TableComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const Products = (props) => {
  const {
    stock,
    stockQuantity,
    loading,
    error,
    categories,
    handleCardClick,
    setStockParams,
    pagination,
  } = { ...props };
  const [showedProducts, setShowedProducts] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(0);

  useEffect(() => {
    if (stock) {
      setShowedProducts(stock);
    }
  }, [stock]);

  const handleSelectCategory = (e, category) => {
    e.preventDefault();
    if (category === selectedCategory) return;
    setSelectedCategory(category);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchInput(value);
  };

  const handleSearchStock = (e) => {
    e.preventDefault();
    setStockParams((values) => ({
      ...values,
      filters: {
        ...values.filters,
        category_id: selectedCategory === 0 ? '' : selectedCategory,
        name: searchInput,
      },
    }));
  };

  return (
    <div className="bg-white mt-auto mb-auto h-full w-3/5 rounded p-4 pt-6">
      <div className="grid grid-cols-12 gap-4 mb-4">
        <div className="col-span-1">
          <button
            type="button"
            id="searchBtn"
            className="btn btn-default waves-effect waves-light py-1 px-2 w-full"
            onClick={handleSearchStock}
          >
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
        <div className="col-span-4">
          <Input
            id="search"
            className="px-2 py-1 w-full"
            placeholder="Buscar producto por nombre o sku"
            value={searchInput}
            onChange={handleSearch}
          />
        </div>
        <div className="col-span-7">
          <div className="flex gap-2 w-full overflow-x-auto" id="categories">
            {categories.map((category) => (
              <Chip
                key={category.id}
                title={category.name}
                selected={selectedCategory === category.id}
                handleClick={(e) => handleSelectCategory(e, category.id)}
              />
            ))}
          </div>
        </div>
      </div>
      <hr />
      {!loading && error && <div>Algo salió mal</div>}
      {loading && <Spinner />}
      {!loading && stock && (
        <div className="h-full overflow-auto">
          <TableComponent
            withItemCard
            showedProducts={showedProducts}
            stockQuantity={stockQuantity}
            handleCardClick={handleCardClick}
            paginationSizeOptions={[10]}
            setQueryParams={setStockParams}
            pagination={pagination}
          />
        </div>
      )}
    </div>
  );
};

export default Products;
