import { useState } from 'react';
import POS from 'transbank-pos-sdk-web';
import PropTypes from 'prop-types';
import ModalTemplate from '../layouts/ModalTemplate';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TableComponent from '../Shared/TableComponent';
import { parseGetSales } from '../../middlewares/parsers/Pos/parseGetSales';
import usePOS from '../../hooks/usePOS';
import useAuth from '../../hooks/useAuth';

const ModalCloseDay = (props) => {
  const { setShowModal, closeModalType } = props;
  const { closeConnection } = usePOS();
  const { logout } = useAuth();
  const [response, setResponse] = useState(null);
  const [waiting, setWaiting] = useState(false);
  const [closeDayWaiting, setCloseDayWaiting] = useState(false);
  const [closeConnectionWaiting, setCloseConnectionWaiting] = useState(false);
  const closeSwal = withReactContent(Swal);
  const [showData, setShowData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    size: 5,
    count: 1,
  });

  const loading = closeDayWaiting || closeConnectionWaiting;

  const handleChangePage = (newPagination) => {
    const { page } = newPagination(pagination).paginationInfo;
    setShowData(
      response?.data?.slice(
        (page - 1) * pagination.size,
        page * pagination.size
      ) || []
    );
    setPagination({ ...pagination, current: page });
  };

  const getDetails = async (e) => {
    e.preventDefault();
    if (loading) return;

    setWaiting(true);
    setResponse(null);
    try {
      await POS.getDetails(false)
        .then((res) => {
          const parsedResponse = parseGetSales(res);
          setResponse(parsedResponse);
          setPagination(parsedResponse.metadata);
          setShowData(
            parsedResponse?.data?.slice(
              (parsedResponse.metadata.current - 1) *
                parsedResponse.metadata.size,
              parsedResponse.metadata.current * parsedResponse.metadata.size
            ) || []
          );
        })
        .finally(() => {
          setWaiting(false);
        });
    } catch (e) {
      console.log(e);
      closeSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const closeDay = async (e) => {
    e.preventDefault();
    if (loading) return;

    setCloseDayWaiting(true);
    try {
      await POS.closeDay()
        .then((res) => {
          // this.$emit('onCloseDayResponse', response)
          if (!res.successful) {
            closeSwal.fire({
              title: `Acción fallida (${res.responseCode})`,
              html: 'No se pudo cerrar el día correctamente',
              icon: 'error',
            });
            setShowModal(false);
            return;
          }
          closeSwal.fire({
            title: `Día cerrado (${res.responseCode})`,
            html: 'Se cerró el día y se cargaron nuevas llaves en el POS correctamente',
            icon: 'success',
          });
          setShowModal(false);
        })
        .finally(() => {
          setCloseDayWaiting(false);
        });
    } catch (e) {
      console.log(e);
      closeSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const closePOSConnection = async (e) => {
    e.preventDefault();
    if (loading) return;

    setCloseConnectionWaiting(true);
    const response = await closeConnection();
    if (!response) {
      closeSwal.fire({
        title: 'Acción fallida',
        html: 'Hubo un error al intentar desconectar el POS, probablemente ya esté desconectado.',
        icon: 'warning',
      });
    }
    closeSwal.fire({
      title: 'POS desconectado',
      html: 'Se desconectó correctamente el POS Integrado.',
      icon: 'success',
    });
    setCloseConnectionWaiting(false);
    setShowModal(false);
  };

  return (
    <ModalTemplate
      title="Cerrar día en POS Integrado"
      setShowModal={setShowModal}
      closeOnOutsideClick={false}
      zIndex="z-[100]"
      zIndexBackground="z-[90]"
    >
      <div className="p-6">
        <p>
          {closeModalType === 'closeDay'
            ? 'Antes de cerrar el día, recuerda revisar que las transacciones tengan concordancia ' +
              'entre el POS Integrado y el sistema.'
            : 'Recuerda que antes de terminar tu día debes revisar que las transacciones tengan ' +
              'concordancia entre el POS Integrado y el sistema para luego cerrar el día en el POS Integrado.'}
        </p>
        <div className="flex flex-col gap-2">
          {/* Get sales of the day */}
          <button
            disabled={loading}
            className="w-full btn-warning px-5 py-2 my-3 shadow rounded text-white"
            onClick={(e) => getDetails(e)}
          >
            {!waiting && <span>Obtener ventas del día</span>}
            {waiting && <span>Obteniendo...</span>}
          </button>
          {/* Close day */}
          <button
            disabled={loading}
            className="w-full btn-default px-5 py-2 shadow rounded text-white"
            onClick={closeDay}
          >
            {!closeDayWaiting && <span>Cerrar día</span>}
            {closeDayWaiting && <span>Cerrando día...</span>}
          </button>
          {/* Close day */}
          {closeModalType === 'logout' && (
            <button
              disabled={loading}
              className="w-full btn-danger px-5 py-2 shadow rounded text-white"
              onClick={logout}
            >
              <span>Cerrar sesión</span>
            </button>
          )}
          {/* Close Connection */}
          {closeModalType === 'disconnect' && (
            <button
              disabled={loading}
              className="w-full btn-danger px-5 py-2 shadow rounded text-white"
              onClick={closePOSConnection}
            >
              {!closeConnectionWaiting ? (
                <span>Desconectar POS</span>
              ) : (
                <span>Desconectando...</span>
              )}
            </button>
          )}
        </div>
        {/* Response */}
        <hr className="mt-4 mb-2" />
        {response && (
          <div className="max-h-84 h-84 overflow-y-auto overflow-x-auto">
            <TableComponent
              title={response.title}
              headers={response.headers}
              data={showData}
              paginationSizeOptions={[pagination.size]}
              pagination={pagination}
              setQueryParams={handleChangePage}
            />
          </div>
        )}
      </div>
    </ModalTemplate>
  );
};

ModalCloseDay.propTypes = {
  setShowModal: PropTypes.func,
  closeModalType: PropTypes.string,
};

export default ModalCloseDay;
