import { useState } from 'react';

// Components
import Input from '../Shared/Input';
import ModalTemplate from '../layouts/ModalTemplate';
import Select from '../Shared/Select';
import Spinner from '../Shared/Spinner';

// Hooks
import useAuth from '../../hooks/useAuth';
import usePosAPIAction from '../../hooks/usePosAPIAction';
import usePosApiFetch from '../../hooks/usePosAPIFetch';

// Middlewares
import parseCategoriesSelect from '../../middlewares/parsers/Categories/parseCategoriesSelect';
import errorsDictionary from '../../middlewares/dictionaries/errorsDictionary';

const generateDefaultValue = (product) => {
  if (!product) {
    return {
      category: '',
      sku: '',
      name: '',
      price: '',
      quantity: '',
    };
  }
  return {
    category: product.categoryId,
    sku: product.sku,
    name: product.name,
    price: product.price,
    quantity: product.quantity,
  };
};

const ModalCreateProduct = (props) => {
  const { product = null, setShowModal } = { ...props };
  const { user } = useAuth();
  const {
    loading: loadingSubmit,
    executeAPIAction,
    error: actionError,
  } = usePosAPIAction();
  const { apiData: categoriesApiData, loading } = usePosApiFetch(
    'categories',
    parseCategoriesSelect,
    { size: 200 }
  );

  const [inputs, setInputs] = useState(generateDefaultValue(product));
  const disabled = !(
    inputs.category &&
    inputs.sku &&
    inputs.name &&
    inputs.price &&
    inputs.quantity
  );

  const generateTitle = () => (product ? 'Editar producto' : 'Crear producto');

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'select-one') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (type === 'text') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (type === 'number') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    const { sku, name, quantity, price, category } = inputs;
    const formData = {
      sku,
      name,
      quantity: parseInt(quantity, 10),
      price: parseInt(price, 10),
      category_id: category,
      merchant_id: user.merchantId,
    };
    const endpoint = `products${product ? `/${product.id}` : ''}`;
    const method = product ? 'PUT' : 'POST';

    const success = await executeAPIAction(endpoint, method, formData);
    if (success) {
      setShowModal(false);
      window.location.reload(false);
    }
  };

  return (
    <ModalTemplate setShowModal={setShowModal} title={generateTitle()}>
      {/*body*/}
      <div className="create-modal relative p-6 w-full flex-auto">
        {(loading || loadingSubmit) && <Spinner />}
        {!loading && !loadingSubmit && (
          <form id="create-product" onSubmit={handleSubmit}>
            <div className="w-full my-3">
              <label
                htmlFor="categories"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Categoría
              </label>
              <Select
                id="category"
                name="category"
                onChange={handleChange}
                value={inputs.category}
                placeholder="Selecciona una categoría"
                options={categoriesApiData}
              />
            </div>
            <div className="w-full my-3">
              <label
                htmlFor="sku"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                SKU
              </label>
              <Input
                placeholder="Ingresa el sku del producto..."
                value={inputs.sku}
                name="sku"
                id="sku"
                onChange={handleChange}
              />
            </div>
            <div className="w-full my-3">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Nombre
              </label>
              <Input
                placeholder="Ingresa el nombre del producto..."
                value={inputs.name}
                name="name"
                id="name"
                onChange={handleChange}
              />
            </div>
            <div className="w-full my-3">
              <label
                htmlFor="price"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Precio unitario
              </label>
              <Input
                placeholder="Ingresa el precio del producto..."
                value={inputs.price}
                name="price"
                id="price"
                type="number"
                min="0"
                onChange={handleChange}
              />
            </div>
            <div className="w-full my-3">
              <label
                htmlFor="stock"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Cantidad en inventario
              </label>
              <Input
                placeholder="Ingresa la cantidad del producto..."
                value={inputs.quantity}
                name="quantity"
                id="quantity"
                type="number"
                min="0"
                onChange={handleChange}
              />
            </div>
            {actionError?.response?.data?.statusCode === 400 && (
              <div className="my-3 w-96 text-sm flex flex-wrap text-center">
                <span className="text-red-500">
                  {errorsDictionary(actionError?.response?.data?.message || '')}
                </span>
              </div>
            )}
            <div className="w-full my-3 flex">
              <button
                disabled={disabled}
                type="submit"
                className={`btn btn-light ${
                  disabled ? 'btn-inverse' : 'btn-primary'
                } waves-effect waves-light py-2 px-5 m-auto`}
              >
                Enviar
              </button>
            </div>
          </form>
        )}
      </div>
    </ModalTemplate>
  );
};

export default ModalCreateProduct;
