// React
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Context
import AuthProvider from './context/AuthContext';
import MerchantProvider from './context/MerchantContext';
import POSProvider from './context/POSContext';

// Views
import Home from './views/Home/Home';
import Auth from './views/Auth/Auth';

// Styles
import './App.css';
import PaymentGateway from './views/PaymentGateway/PaymentGateway';

function App() {
  return (
    <AuthProvider>
      <POSProvider>
        <MerchantProvider>
          <BrowserRouter>
            <Routes>
              <Route exact path="/" element={<Auth />} />
              <Route exact path="/pos" element={<Home />} />
              <Route
                exact
                path="/payments/:transactionToken"
                element={<PaymentGateway />}
              />
            </Routes>
          </BrowserRouter>
        </MerchantProvider>
      </POSProvider>
    </AuthProvider>
  );
}

export default App;
