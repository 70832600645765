import { useContext } from 'react';
import { POSContext } from '../context/POSContext';
import POS from 'transbank-pos-sdk-web';
import usePosAPIAction from './usePosAPIAction';

const usePOS = () => {
  const {
    socketConnected,
    activePort,
    connected,
    setConnected,
    closeConnection,
    resultData,
    setResultData,
    error,
  } = useContext(POSContext);
  const { executeAPIAction } = usePosAPIAction();

  const handleExitPayment = async (
    salesResponse,
    setActionLoading,
    setShowModal,
    ticketData
  ) => {
    // Send request
    const formData = {
      transaction_id: ticketData.id,
      merchant_id: ticketData.merchantId,
      posResponse: salesResponse || '{}',
      status: 'failure',
    };
    const endpoint = `transactions/${ticketData.id}`;

    setActionLoading(true);
    await executeAPIAction(endpoint, 'PUT', formData);
    setActionLoading(false);

    setShowModal(false);
  };

  const handleSuccessPayment = async (
    salesResponse,
    setActionLoading,
    ticketData
  ) => {
    setActionLoading(true);
    // Send request
    const formData = {
      transaction_id: ticketData.id,
      merchant_id: ticketData.merchantId,
      posResponse: salesResponse ? JSON.stringify(salesResponse) : '{}',
      operationNumber: salesResponse ? salesResponse.operationNumber : null,
      authorizationCode: salesResponse ? salesResponse.authorizationCode : null,
      status: 'success',
    };
    const endpoint = `transactions/${ticketData.id}`;

    const success = await executeAPIAction(endpoint, 'PUT', formData);
    setActionLoading(false);

    return success;
  };

  const handlePaymentGatewayExitPayment = async (
    paymentIntentToken,
    salesResponse,
    setActionLoading,
    ticketData
  ) => {
    console.log(salesResponse);
    const parsePosStatus = (status) => {
      const posStatusDict = {
        1: 'failure',
        7: 'canceled',
      };
      if (status in posStatusDict) {
        return posStatusDict[status];
      }
      return 'failure';
    };
    // Send request
    const formData = {
      payment_intent_token: paymentIntentToken,
      transaction_id: ticketData.id,
      merchant_id: ticketData.merchantId,
      posResponse: JSON.stringify(salesResponse || {}),
      status: parsePosStatus((salesResponse || {})['responseCode']),
    };
    const endpoint = `transactions/payment/${ticketData.id}`;

    setActionLoading(true);
    await executeAPIAction(endpoint, 'PUT', formData, true, true);
    setActionLoading(false);
  };

  const handlePaymentGatewaySuccessPayment = async (
    paymentIntentToken,
    salesResponse,
    setActionLoading,
    ticketData
  ) => {
    setActionLoading(true);
    // Send request
    const formData = {
      payment_intent_token: paymentIntentToken,
      transaction_id: ticketData.id,
      merchant_id: ticketData.merchantId,
      posResponse: salesResponse ? JSON.stringify(salesResponse) : '{}',
      operationNumber: salesResponse ? salesResponse.operationNumber : null,
      authorizationCode: salesResponse ? salesResponse.authorizationCode : null,
      status: 'success',
    };
    const endpoint = `transactions/payment/${ticketData.id}`;

    const success = await executeAPIAction(
      endpoint,
      'PUT',
      formData,
      true,
      true
    );
    setActionLoading(false);

    return success;
  };

  const doSale = async (
    paymentSwal,
    setSalesResponse,
    ticketData,
    setPosItems,
    isPaymentGateway = false
  ) => {
    // Hacer la venta y cuando llegue la respuesta, vaciar la venta y guardar los detalles de la transacción en
    // la variable saleResponse para poder mostrarlos
    paymentSwal.fire({
      title: 'Solicite al cliente que opere el POS',
      icon: 'info',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
    });

    setSalesResponse(null);
    try {
      return await POS.doSale(ticketData.amount, ticketData.ticket, (data) => {
        paymentSwal.fire({
          title: data.responseMessage,
          icon: 'info',
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        });
      }).then((saleResponse) => {
        //Acá llega la respuesta de la venta. Si saleResponse.responseCode es 0, entonces la comproa fue aprobada
        setSalesResponse(saleResponse);
        if (saleResponse.responseCode === 0) {
          // Mostramos mensaje de éxito y limpiamos el total de la venta si response code es 0
          setPosItems([]);
          setSalesResponse(saleResponse);
          console.log('Pago correcto!');
          return [true, saleResponse];
        } else {
          // Mostramos mensaje de error si response code es distinto de 0
          paymentSwal
            .fire({
              title: `Transacción fallida (${saleResponse.responseCode})`,
              html: 'La transacción no ha sido aprobada. Puede reintentar pago',
              icon: 'error',
            })
            .then(function () {
              if (isPaymentGateway) {
                window.location = ticketData.return_url;
              }
            });
          return [false, saleResponse];
        }
      });
    } catch (e) {
      console.log(e);
      paymentSwal
        .fire({
          title: 'Transacción fallida',
          html: 'La transacción ha fallado por problemas al conectarse al POS',
          icon: 'error',
        })
        .then(function () {
          if (isPaymentGateway) {
            window.location = ticketData.return_url;
          }
        });
      return [false, null];
    }
  };

  const executePaySaleOnPOS = async (
    setActionLoading,
    paymentSwal,
    salesResponse,
    setSalesResponse,
    ticketData,
    setPosItems,
    setShowModal
  ) => {
    if (!connected) return;

    setActionLoading(true);
    const [posSuccess, posSalesResponse] = await doSale(
      paymentSwal,
      setSalesResponse,
      ticketData,
      setPosItems
    );
    if (!posSuccess || posSuccess === null) {
      await handleExitPayment(
        salesResponse,
        setActionLoading,
        setShowModal,
        ticketData
      );
      return;
    }
    if (posSuccess) {
      // Send request
      const success = await handleSuccessPayment(
        posSalesResponse,
        setActionLoading,
        ticketData
      );
      if (success) {
        setPosItems([]);
        paymentSwal.fire({
          title: <p>Transaccion completada</p>,
          html: <i>La transacción fue completada correctamente</i>,
          icon: 'success',
        });
        setShowModal(false);
      } else {
        paymentSwal.fire({
          title: <p>Transaccion completada sin enviar</p>,
          html: (
            <i>
              La transacción fue completada correctamente pero no se pudo envíar
              al sistema.
            </i>
          ),
          icon: 'error',
        });
        setPosItems([]);
        setShowModal(false);
      }
    }
  };

  const executePaymentGatewayPaySaleOnPOS = async (
    paymentIntentToken,
    setActionLoading,
    paymentSwal,
    salesResponse,
    setSalesResponse,
    ticketData
  ) => {
    if (!connected) return;

    setActionLoading(true);
    const [posSuccess, posSalesResponse] = await doSale(
      paymentSwal,
      setSalesResponse,
      ticketData,
      () => {}, // setPosItems
      true // isPaymentGateway
    );
    if (!posSuccess || posSuccess === null) {
      await handlePaymentGatewayExitPayment(
        paymentIntentToken,
        posSalesResponse || salesResponse,
        setActionLoading,
        ticketData
      );
      return;
    }
    if (posSuccess) {
      // Send request
      const success = await handlePaymentGatewaySuccessPayment(
        paymentIntentToken,
        posSalesResponse,
        setActionLoading,
        ticketData
      );
      if (success) {
        paymentSwal
          .fire({
            title: <p>Transaccion completada</p>,
            html: <i>La transacción fue completada correctamente</i>,
            icon: 'success',
          })
          .then(function () {
            window.location = ticketData.return_url;
          });
      } else {
        paymentSwal
          .fire({
            title: <p>Transaccion completada sin enviar</p>,
            html: (
              <i>
                La transacción fue completada correctamente pero no se pudo
                envíar al sistema. Hay que corregirla manualmente.
              </i>
            ),
            icon: 'error',
          })
          .then(function () {
            window.location = ticketData.return_url;
          });
      }
    }
  };

  return {
    socketConnected,
    activePort,
    connected,
    setConnected,
    closeConnection,
    resultData,
    setResultData,
    error,
    executePaySaleOnPOS,
    executeExitPayment: handleExitPayment,
    executePaymentGatewayPaySaleOnPOS,
    executePaymentGatewayExitPayment: handlePaymentGatewayExitPayment,
  };
};

export default usePOS;
