import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableComponent from '../Shared/TableComponent';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const TransactionsTable = (props) => {
  const { data, headers, pagination, setQueryParams, handleShowTransaction } = {
    ...props,
  };

  const setAction = (row) => {
    return (
      <td key={`${row.id}-action`} className="p-2">
        <button
          type="button"
          className="btn btn-light btn-default waves-effect waves-light py-0 px-2"
          id="show"
          onClick={() => handleShowTransaction(row)}
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      </td>
    );
  };

  return (
    <div className="w-full">
      <TableComponent
        withActions
        setAction={setAction}
        pagination={pagination}
        paginationSizeOptions={[10]}
        title="Transacciones"
        data={data}
        headers={headers}
        rowKey="id"
        setQueryParams={setQueryParams}
      />
    </div>
  );
};

export default TransactionsTable;
