import { useEffect, useState } from 'react';
import useAuth from './useAuth';
import { API_URL } from '../utils/urls';
import axios, { AxiosError } from 'axios';
import useMerchant from './useMerchant';

const usePosApiFetch = (
  endpoint,
  parseFunction = (data) => data,
  filters = {}
) => {
  const { user, getAccessToken, refreshTokenOrLogout, logout } = useAuth();
  const { selectedMerchant } = useMerchant();
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [refetchIndex, setRefetchIndex] = useState(0);
  const [queryParams, setQueryParams] = useState({
    paginationInfo: {
      size: filters.size || 10,
      page: 1,
    },
    filters: {
      dateFrom: filters.dateFrom || '',
      dateTo: filters.dateTo || '',
      status: filters.status || 'all',
      active: filters.active || 'true',
      category_id: '',
      name: '',
    },
  });

  const refetch = () =>
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);

  useEffect(() => {
    const getData = async (retry = true) => {
      try {
        setError(null);
        setLoading(true);
        const accessToken = getAccessToken();

        const parsedQueryParams = Object.entries(queryParams.filters).reduce(
          (acc, [key, val]) => {
            if (key === 'status' && val === 'all') {
              return acc;
            }
            if (val instanceof Date) {
              acc[key] = val;
            } else if (val instanceof Array) {
              acc[key] = val.reduce((statusString, curr, idx) => {
                if (idx === 0) return `${curr}`;
                return `${statusString} ${curr}`;
              }, '');
            } else if (val !== '' && val !== null) {
              acc[key] = val;
            }
            return acc;
          },
          {}
        );
        const queryParamsPaginated = {
          ...queryParams.paginationInfo,
          ...parsedQueryParams,
        };
        const queryString = Object.keys(queryParamsPaginated)
          .map((key) => `${key}=${queryParamsPaginated[key]}`)
          .join('&');

        const url = `${API_URL}/${endpoint}?${
          selectedMerchant && `merchant_id=${selectedMerchant}&`
        }${queryString}`;

        const splitedURL = API_URL.split('/');
        const headers = {
          Authorization: `Bearer ${accessToken}`,
          'Access-Control-Allow-Origin': `${splitedURL[0]}//${splitedURL[2]}`,
        };

        const res = await axios.get(url, {
          headers,
          withCredentials: true,
        });

        if (res.status === 200) {
          const { data } = res;
          const parsedData = parseFunction(data);
          setApiData(parsedData);
        } else {
          setError(res);
        }
      } catch (err) {
        if (err instanceof AxiosError) {
          if (err?.response?.status === 401 && retry) {
            await refreshTokenOrLogout();
            await getData(false);
          }
          if (err?.response?.status === 401 && !retry) {
            await logout();
            setError(err);
          }
        } else {
          setError(err);
        }
      } finally {
        setLoading(false);
      }
    };

    getData();
    return () => {};
  }, [user.id, endpoint, refetchIndex, queryParams, selectedMerchant]);

  return {
    apiData,
    loading,
    error,
    refetch,
    setQueryParams,
    queryParams,
  };
};

export default usePosApiFetch;
