import ItemCard from '../Shared/ItemCard';

const TableItemCards = (props) => {
  const { showedProducts, stockQuantity, handleCardClick } = { ...props };

  return (
    <div
      className="w-full max-h-full grid grid-cols-5 gap-2 mt-4 pb-3"
      id="stocks"
    >
      {showedProducts.map((item) => {
        const quantity =
          stockQuantity.filter((product) => item.sku === product.sku)[0]
            ?.quantity || 0;
        return (
          <ItemCard
            key={item.sku}
            sku={item.sku}
            name={item.name}
            tooltip={item.nameTooltip}
            needTooltip={item.nameTooltipNeed}
            category={item.category}
            price={item.price}
            stock={quantity}
            handleClick={(e) => handleCardClick(e, item.sku)}
          />
        );
      })}
    </div>
  );
};

export default TableItemCards;
