const dateConverter = (date) => {
  if (date === null) return '-';
  const unixTime = new Date(date);
  const parsedMinutes = unixTime.getMinutes().toString().padStart(2, '0');
  const convertedDate = `${unixTime.getDate().toString().padStart(2, '0')}/${(
    unixTime.getMonth() + 1
  )
    .toString()
    .padStart(
      2,
      '0'
    )}/${unixTime.getFullYear()} ${unixTime.getHours()}:${parsedMinutes}`;

  return convertedDate;
};

export default dateConverter;
