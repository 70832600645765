import { useState } from 'react';
import PropTypes from 'prop-types';
import POS from 'transbank-pos-sdk-web';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

// Components
import ModalTemplate from '../layouts/ModalTemplate';

// Middlewares
import {
  parseGetLastSale,
  parseGetSales,
  parseGetTotals,
} from '../../middlewares/parsers/Pos/parseGetSales';
import TableComponent from '../Shared/TableComponent';

const ModalGetSalesOfTheDay = (props) => {
  const { setShowModal, setShowCloseDayModal } = props;
  const [waiting, setWaiting] = useState(false);
  const [waitingLastSale, setWaitingLastSale] = useState(false);
  const [waitingTotals, setWaitingTotals] = useState(false);
  const [showData, setShowData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    size: 5,
    count: 1,
  });
  const [response, setResponse] = useState(null);
  const [printOnPos, setPrintOnPos] = useState(false);
  const alertSwal = withReactContent(Swal);
  const loading = waiting || waitingLastSale || waitingTotals;

  const handleChangePage = (newPagination) => {
    const { page } = newPagination(pagination).paginationInfo;
    setShowData(
      response?.data?.slice(
        (page - 1) * pagination.size,
        page * pagination.size
      ) || []
    );
    setPagination({ ...pagination, current: page });
  };

  const handleChange = () => {
    setPrintOnPos(!printOnPos);
  };

  const getDetails = async (e) => {
    e.preventDefault();
    if (loading) return;

    setWaiting(true);
    setResponse(null);
    try {
      await POS.getDetails(printOnPos)
        .then((res) => {
          const parsedResponse = parseGetSales(res);
          setResponse(parsedResponse);
          setPagination(parsedResponse.metadata);
          setShowData(
            parsedResponse?.data?.slice(
              (parsedResponse.metadata.current - 1) *
                parsedResponse.metadata.size,
              parsedResponse.metadata.current * parsedResponse.metadata.size
            ) || []
          );
        })
        .finally(() => {
          setWaiting(false);
        });
    } catch (e) {
      console.log(e);
      alertSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const getLastSale = async (e) => {
    e.preventDefault();
    if (loading) return;

    setWaitingLastSale(true);
    setResponse(null);
    try {
      await POS.getLastSale()
        .then((res) => {
          const parsedResponse = parseGetLastSale(res);
          setResponse(parsedResponse);
          setPagination(parsedResponse.metadata);
          setShowData(
            parsedResponse?.data?.slice(
              (parsedResponse.metadata.current - 1) *
                parsedResponse.metadata.size,
              parsedResponse.metadata.current * parsedResponse.metadata.size
            ) || []
          );
        })
        .finally(() => {
          setWaitingLastSale(false);
        });
    } catch (e) {
      console.log(e);
      alertSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const getTotals = async (e) => {
    e.preventDefault();
    if (loading) return;

    setWaitingTotals(true);
    setResponse(null);
    try {
      await POS.getTotals()
        .then((res) => {
          // this.$emit('onGetTotalsResponse', response);
          const parsedResponse = parseGetTotals(res);
          setResponse(parsedResponse);
          setPagination(parsedResponse.metadata);
          setShowData(
            parsedResponse?.data?.slice(
              (parsedResponse.metadata.current - 1) *
                parsedResponse.metadata.size,
              parsedResponse.metadata.current * parsedResponse.metadata.size
            ) || []
          );
        })
        .finally(() => {
          setWaitingTotals(false);
        });
    } catch (e) {
      console.log(e);
      alertSwal.fire({
        title: 'Acción fallida',
        html: 'La acción ha fallado por problemas al conectarse al POS',
        icon: 'warning',
      });
      setShowModal(false);
    }
  };

  const closeDay = async (e) => {
    e.preventDefault();

    setShowCloseDayModal(true);
    setShowModal(false);
  };

  return (
    <ModalTemplate
      setShowModal={setShowModal}
      closeOnOutsideClick
      title="Obtener ventas del día en POS Integrado"
    >
      <div className="p-6">
        <label htmlFor="printOnPos">
          <input
            className="mr-2 before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-md border border-gray-300 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-gray-300 before:opacity-0 before:transition-opacity checked:border-blue-500 checked:bg-blue-500 checked:before:bg-blue-500 hover:before:opacity-10"
            type="checkbox"
            id="printOnPos"
            checked={printOnPos}
            onChange={handleChange}
          />
          Imprimir en el POS (solo para las ventas del día)
        </label>
        <hr className="mt-4 mb-2" />
        {/* Get last sale */}
        <button
          disabled={loading}
          className="w-full btn-warning px-5 py-2 shadow rounded text-white"
          onClick={(e) => getLastSale(e)}
        >
          {!waitingLastSale && <span>Imprimir la última venta</span>}
          {waitingLastSale && <span>Imprimiendo última venta...</span>}
        </button>
        {/* Get sales of the day */}
        <button
          disabled={loading}
          className="w-full btn-default px-5 py-2 my-3 shadow rounded text-white"
          onClick={(e) => getDetails(e)}
        >
          {!waiting && <span>Obtener ventas del día</span>}
          {waiting && <span>Obteniendo...</span>}
        </button>
        {/* Get totals of the day */}
        <button
          disabled={loading}
          className="w-full btn-primary px-5 py-2 mb-3 shadow rounded text-white"
          onClick={(e) => getTotals(e)}
        >
          {!waitingTotals && <span>Imprimir resumen de ventas totales</span>}
          {waitingTotals && <span>Obteniendo...</span>}
        </button>
        {/* Close day */}
        <button
          disabled={loading}
          className="w-full btn-danger px-5 py-2 shadow rounded text-white"
          onClick={closeDay}
        >
          <span>Cerrar día</span>
        </button>
        <hr className="mt-4 mb-2" />
        {response && (
          <div className="max-h-84 h-84 overflow-y-auto overflow-x-auto">
            <TableComponent
              title={response.title}
              headers={response.headers}
              data={showData}
              paginationSizeOptions={[pagination.size]}
              pagination={pagination}
              setQueryParams={handleChangePage}
            />
          </div>
        )}
        {/* <p className="w-full break-words">{JSON.stringify(response)}</p> */}
      </div>
    </ModalTemplate>
  );
};

ModalGetSalesOfTheDay.propTypes = {
  setShowModal: PropTypes.func,
  setShowCloseDayModal: PropTypes.func,
};

export default ModalGetSalesOfTheDay;
