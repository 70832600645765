import PropType from 'prop-types';

const Select = (props) => {
  const { className, options, withoutBlank, ...selectProps } = props;

  return (
    <select
      {...selectProps}
      className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${className}`}
    >
      {!withoutBlank && <option value="" defaultValue></option>}
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.name}
        </option>
      ))}
    </select>
  );
};

Select.propTypes = {
  className: PropType.string,
  options: PropType.arrayOf(
    PropType.shape({
      id: PropType.string,
      name: PropType.string,
    })
  ),
  withoutBlank: PropType.bool,
};

export default Select;
