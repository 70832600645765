import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Components
import ModalConnectToPOS from '../../components/Pos/ModalConnectToPOS';
import ModalCloseDay from '../../components/Pos/ModalCloseDay';
import ModalPOSActionsMenu from '../../components/Pos/ModalPOSActionsMenu';
import CloseDayToast from '../../components/Pos/CloseDayToast';
import TemplatePage from '../../components/layouts/TemplatePage';
import Transactions from '../Transactions/Transaction';
import Sales from '../Sales/Sales';
import Users from '../Users/Users';

// Hooks
import useAuth from '../../hooks/useAuth';
import usePOS from '../../hooks/usePOS';

const Home = () => {
  const { socketConnected } = usePOS();
  const [view, setView] = useState('Cashier');
  const [closeModalType, setCloseModalType] = useState(false);
  const [showCloseDayModal, setShowCloseDayModal] = useState(false);
  const [showConnectToPOSModal, setShowConnectToPOSModal] = useState(false);
  const [showPOSActionsMenuModal, setShowPOSActionsMenuModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [closedCloseDayToast, setClosedCloseDayToast] = useState(false);
  const { user } = useAuth();
  const agentSwal = withReactContent(Swal);

  useEffect(() => {
    const intervalId = setInterval(
      () => {
        const options = {
          timeZone: 'America/Santiago',
          hour12: false, // Use 24-hour format
          hour: '2-digit', // Get the hour part only
        };
        const currentHour = new Date().toLocaleTimeString('en-US', options);
        if (
          parseInt(currentHour, 10) >= 18 &&
          !showToast &&
          !closedCloseDayToast
        ) {
          setShowToast(true);
        }
      },
      1000 * 60 * 5 // Update every 5 minutes
    );

    return () => clearInterval(intervalId);
  }, [closedCloseDayToast, showToast]);

  useEffect(() => {
    let timerId;

    timerId = setTimeout(() => {
      if (!socketConnected) {
        agentSwal.fire({
          title: <p>No se pudo conectar con el agente Transbank POS</p>,
          html: (
            <i>
              Verifique que el agente se haya inicializado en este computador
            </i>
          ),
          icon: 'error',
        });
      }
    }, 1500);

    if (socketConnected) {
      agentSwal.close();
      return;
    }

    return () => clearTimeout(timerId);
  }, [socketConnected]);

  if (user === null) {
    return <Navigate to="/" />;
  }

  // useEffect(() => {
  //   window.onbeforeunload = confirmExit;
  //   function confirmExit() {
  //     return 'show warning';
  //   }
  // }, []);

  return (
    <TemplatePage
      view={view}
      setView={setView}
      setShowConnectToPOSModal={setShowConnectToPOSModal}
      setShowPOSActionsMenuModal={setShowPOSActionsMenuModal}
      setShowCloseDayModal={setShowCloseDayModal}
      setCloseModalType={setCloseModalType}
    >
      {showConnectToPOSModal && (
        <ModalConnectToPOS setShowModal={setShowConnectToPOSModal} />
      )}
      {showPOSActionsMenuModal && (
        <ModalPOSActionsMenu
          setShowModal={setShowPOSActionsMenuModal}
          setShowCloseDayModal={setShowCloseDayModal}
          setCloseModalType={setCloseModalType}
        />
      )}
      {showCloseDayModal && (
        <ModalCloseDay
          setShowModal={setShowCloseDayModal}
          closeModalType={closeModalType}
          setClosedCloseDayToast={setClosedCloseDayToast}
        />
      )}
      {view === 'Cashier' && <Sales />}
      {view === 'Transactions' && (
        <Transactions setShowCloseDayModal={setShowCloseDayModal} />
      )}
      {view === 'Users' && <Users />}
      {showToast && (
        <CloseDayToast
          setShowToast={setShowToast}
          setClosedCloseDayToast={setClosedCloseDayToast}
          setShowCloseDayModal={setShowCloseDayModal}
          setCloseModalType={setCloseModalType}
        />
      )}
    </TemplatePage>
  );
};

export default Home;
