import currencyFormat from '../../../utils/currencyFormat';

const parseSalesResult = (result) => {
  return {
    response: result['responseMessage'],
    function: result['functionCode'],
    commerceCode: result['commerceCode'],
    terminalId: result['terminalId'],
    ticket: result['ticket'],
    authorizationCode: result['authorizationCode'],
    amount: result['amount'],
    parsedAmount: currencyFormat(result['amount']),
    last4Digits: result['last4Digits'],
    operationNumber: result['operationNumber'],
    cardType: result['cardType'],
    accountingDate: result['accountingDate'],
    accountNumber: result['accountNumber'],
    cardBrand: result['cardBrand'],
    realDate: result['realDate'],
    employeeId: result['employeeId'],
    tip: result['tip'],
    sharesAmount: result['sharesAmount'],
    sharesNumber: result['sharesNumber'],
  };
};

export const parseGetTotals = (res) => {
  const headers = [
    { attrName: 'txCount', name: 'Número de transacciones' },
    { attrName: 'txTotal', name: 'Total de transacciones' },
  ];

  const parsedData = {
    title: 'Resumen de ventas totales en POS',
    headers,
    data: [{ txCount: res['txCount'], txTotal: res['txTotal'] }],
    metadata: {
      current: 1,
      size: 5,
      count: 1,
    },
  };

  return parsedData;
};

export const parseGetLastSale = (res) => {
  const headers = [
    { attrName: 'terminalId', name: 'ID de Terminal' },
    { attrName: 'ticket', name: 'N° Ticket' },
    { attrName: 'realDate', name: 'Fecha' },
    { attrName: 'response', name: 'Estado' },
    { attrName: 'parsedAmount', name: 'Monto' },
  ];
  const parsedData = {
    title: 'Ultima venta en POS',
    headers,
    data: [parseSalesResult(res)],
    metadata: {
      current: 1,
      size: 5,
      count: 1,
    },
  };
  return parsedData;
};

export const parseGetSales = (res) => {
  const headers = [
    { attrName: 'terminalId', name: 'ID de Terminal' },
    { attrName: 'operationNumber', name: 'N° de operación' },
    { attrName: 'ticket', name: 'N° Ticket' },
    { attrName: 'realDate', name: 'Fecha' },
    { attrName: 'response', name: 'Estado' },
    { attrName: 'parsedAmount', name: 'Monto' },
  ];
  const parsedData = {
    title: 'Ventas en POS',
    headers,
    data: res.map((result) => parseSalesResult(result)),
    metadata: {
      current: 1,
      size: 5,
      count: Math.ceil(res.length / 5),
    },
  };
  return parsedData;
};
