import {
  faCheck,
  faPenToSquare,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import TableComponent from '../Shared/TableComponent';
import ModalTemplate from '../layouts/ModalTemplate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePosApiFetch from '../../hooks/usePosAPIFetch';
import parseCategoriesRequest from '../../middlewares/parsers/Categories/parseCategoriesRequest';
import Spinner from '../Shared/Spinner';
import usePosAPIAction from '../../hooks/usePosAPIAction';
import { useState } from 'react';

const ModalCategories = (props) => {
  const { setShowModal, setShowCreateCategoryModal, setSelectedCategory } = {
    ...props,
  };
  const { loading: loadingDelete, executeAPIAction } = usePosAPIAction();
  const { apiData, loading, error, refetch, setQueryParams } = usePosApiFetch(
    'categories/manage',
    parseCategoriesRequest
  );
  const [active, setActive] = useState(true);

  const handleEditClick = (e, row) => {
    e.preventDefault();
    setSelectedCategory(row);
    setShowCreateCategoryModal(true);
    setShowModal(false);
  };

  const handleActivateClick = async (e, row) => {
    e.preventDefault();
    const endpoint = `categories/${row.id}/activate`;
    const method = 'PUT';

    const success = await executeAPIAction(endpoint, method);
    if (success) {
      refetch();
      window.location.reload(false);
    }
  };

  const handleActiveClick = (e) => {
    e.preventDefault();
    setQueryParams((values) => ({
      ...values,
      filters: {
        ...values.filters,
        active: !active,
      },
    }));
    setActive(!active);
  };

  const handleDeleteClick = async (e, row) => {
    e.preventDefault();

    const endpoint = `categories/${row.id}`;
    const method = 'DELETE';

    const success = await executeAPIAction(endpoint, method);
    if (success) {
      refetch();
      window.location.reload(false);
    }
  };

  const setAction = (row) => {
    return (
      <td key={`${row['id']}-action`} className="p-2">
        <button
          type="button"
          className="btn btn-left btn-light btn-warning waves-effect waves-light py-1 px-2"
          id="edit"
          onClick={(e) => handleEditClick(e, row)}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
        {!row.active && (
          <button
            type="button"
            className="btn btn-right btn-light btn-success waves-effect waves-light py-1 px-2 group"
            title="This is my title"
            id="activate"
            onClick={(e) => handleActivateClick(e, row)}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span
              className="absolute hidden group-hover:flex ml-4
              -mt-14 translate-y-full w-48 px-2 py-1
              bg-gray-700 rounded-lg text-center text-white text-sm"
            >
              Activar categoría
            </span>
          </button>
        )}
        {row.active && (
          <button
            type="button"
            className="btn btn-right btn-light btn-danger waves-effect waves-light py-1 px-2 group"
            id="delete"
            onClick={(e) => handleDeleteClick(e, row)}
          >
            <FontAwesomeIcon icon={faXmark} />
            <span
              className="absolute hidden group-hover:flex ml-4
              -mt-14 translate-y-full w-48 px-2 py-1
              bg-gray-700 rounded-lg text-center text-white text-sm"
            >
              Desactivar categoría
            </span>
          </button>
        )}
      </td>
    );
  };

  return (
    <ModalTemplate setShowModal={setShowModal} title={'Categorías'}>
      {/*body*/}
      <div className="relative p-6 flex-auto w-full">
        <div className="flex flex-row-reverse mb-2">
          <button
            type="button"
            id="searchBtn"
            className="btn btn-default waves-effect waves-light py-1 px-2"
            onClick={handleActiveClick}
          >
            {active ? 'Ver inactivos' : 'Ver activos'}
          </button>
        </div>
        {(loading || loadingDelete) && <Spinner />}
        {!loading && !loadingDelete && !error && (
          <TableComponent
            withActions
            pagination={apiData?.metadata || {}}
            setQueryParams={setQueryParams}
            paginationSizeOptions={[10]}
            headers={apiData.parsedData.headers}
            data={apiData.parsedData.parsedData}
            setAction={setAction}
            rowKey={'id'}
          />
        )}
        {!loading && !loadingDelete && error && (
          <div>Ocurrió un error, inténtalo más tarde</div>
        )}
      </div>
    </ModalTemplate>
  );
};

export default ModalCategories;
