import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// hooks
import useAuth from '../../hooks/useAuth';

// components
import Select from '../Shared/Select';

// icons
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const UsersHeaders = (props) => {
  const { filters, setFilters, handleFilter, setShowCreateUserModal } = props;
  const { user } = useAuth();
  const disabled = false;
  const statusOptions = [
    { id: 'true', name: 'Activos' },
    { id: 'false', name: 'Inactivo' },
  ];

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'select-one') {
      setFilters((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  return (
    <div className="w-full flex justify-between">
      <div className="my-auto">
        <div className="flex flex-col gap-2">
          <span className="font-bold text-xl align-middle">Usuarios</span>
          {user.roles.includes('super_admin') && (
            <div className="group">
              <button
                type="submit"
                className="btn btn-default btn-light waves-effect waves-light py-2 px-3 m-auto ml-0"
                onClick={() => setShowCreateUserModal(true)}
              >
                <span className="btn-label !mr-2">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                Crear nuevo usuario
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="p-2 flex gap-3">
        <div className="flex flex-col gap-1 w-full">
          <label
            htmlFor="status"
            className="inline-block text-sm font-semibol align-middle text-gray-500"
          >
            Estado:
          </label>
          <Select
            id="active"
            name="active"
            onChange={handleChange}
            value={filters.active}
            placeholder="Selecciona un estado"
            options={statusOptions}
            withoutBlank
          />
        </div>
        <div className="mt-auto w-full flex">
          <button
            disabled={disabled}
            type="submit"
            className={`btn btn-light ${
              disabled ? 'btn-inverse' : 'btn-default'
            } waves-effect waves-light py-2 px-5 m-auto`}
            onClick={handleFilter}
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  );
};

UsersHeaders.propTypes = {
  filters: PropTypes.exact({
    active: PropTypes.string,
  }),
  setFilters: PropTypes.func,
  handleFilter: PropTypes.func,
  setShowCreateUserModal: PropTypes.func,
};

export default UsersHeaders;
