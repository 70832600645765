import { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Components
import PaymentModal from '../../components/Sales/PaymentModal';
import Pos from '../../components/Sales/Pos';
import Products from '../../components/Sales/Products';

// Hooks
import useAuth from '../../hooks/useAuth';
import usePosAPIAction from '../../hooks/usePosAPIAction';
import usePosApiFetch from '../../hooks/usePosAPIFetch';

// Middlewares
import parseStockProducts from '../../middlewares/parsers/Sales/parseStockProducts';
import parseStockCategories from '../../middlewares/parsers/Sales/parseStockCategories';

// Styles
import '../../styles/sales.css';

const Sales = () => {
  const { user } = useAuth();
  const {
    responseData,
    loading: loadingPayment,
    executeAPIAction,
  } = usePosAPIAction();
  const {
    apiData: stockData,
    loading,
    error,
    setQueryParams,
  } = usePosApiFetch('products/filter', parseStockProducts, { size: 15 });
  const {
    apiData: categoriesData,
    loading: categoriesLoading,
    error: categoriesError,
  } = usePosApiFetch('categories', parseStockCategories, { size: 200 });
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [posItems, setPosItems] = useState([]);
  const [stockQuantity, setStockQuantity] = useState([]);
  const [categories, setCategories] = useState([{ name: 'Todos', id: 0 }]);
  const [discount, setDiscount] = useState(0);
  const paymentSwal = withReactContent(Swal);

  useEffect(() => {
    if (!categoriesLoading && categoriesData) {
      setCategories([{ name: 'Todos', id: 0 }, ...categoriesData]);
    }
  }, [categoriesLoading, categoriesData]);

  useEffect(() => {
    if (!loading && stockData) {
      setStockQuantity(
        stockData.parsedData?.parsedData?.map((product) => ({
          id: product.id,
          sku: product.sku,
          quantity: product.quantity,
        }))
      );
    }
  }, [stockData, loading]);

  const updateStockQuantity = (sku, change, reset) => {
    const product = stockQuantity.filter((item) => item.sku === sku)[0];
    if (product === null) return;
    const quantity =
      stockData.parsedData.parsedData.filter((item) => item.sku === sku)[0]
        ?.quantity || 0;
    const stockQuantityWithoutProduct = stockQuantity.filter(
      (item) => item.sku !== sku
    );
    setStockQuantity([
      ...stockQuantityWithoutProduct,
      {
        ...product,
        quantity: reset ? quantity : quantity - change,
      },
    ]);
  };

  const handleCardClick = (e, sku) => {
    e.preventDefault();
    const product = (stockData?.parsedData?.parsedData || []).filter(
      (item) => item.sku === sku
    )[0];
    if (product.quantity === 0) return;
    const inPosItems = posItems.filter((item) => item.sku === sku);
    // If it's not in pos
    if (inPosItems.length === 0) {
      updateStockQuantity(product.sku, 1);
      setPosItems(
        [
          ...posItems,
          {
            id: product.id,
            sku: product.sku,
            name: product.name,
            quantity: 1,
            unitPrice: product.price,
          },
        ].sort((a, b) => a.id - b.id)
      );
      return;
    }
    // If there's no more stock available
    if (inPosItems[0].quantity >= product.quantity) return;
    const posWithoutProduct = posItems.filter((item) => item.sku !== sku);
    updateStockQuantity(inPosItems[0].sku, inPosItems[0].quantity + 1);
    setPosItems(
      [
        ...posWithoutProduct,
        {
          ...inPosItems[0],
          quantity: inPosItems[0].quantity + 1,
        },
      ].sort((a, b) => a.id - b.id)
    );
  };

  const handleQuantityChange = (e) => {
    const { value, name } = e.target;
    const originalStock =
      stockData.parsedData.filter((item) => item.sku === name)[0]?.quantity ||
      0;
    const toUpdateValue =
      parseInt(value || 0, 10) > originalStock
        ? originalStock
        : parseInt(value || 0, 10);

    updateStockQuantity(name, toUpdateValue, toUpdateValue <= 0);
    setPosItems((values) => {
      const unSelectedItems = values.filter((item) => item.sku !== name);
      if (toUpdateValue === '0') {
        return unSelectedItems;
      }
      const selectedItem = values.filter((item) => item.sku === name)[0];
      return [...unSelectedItems, { ...selectedItem, quantity: toUpdateValue }];
    });
  };

  const handleDeleteClick = (e, sku) => {
    e.preventDefault();
    updateStockQuantity(sku, 0, true);
    setPosItems((values) => values.filter((item) => item.sku !== sku));
  };

  const handleCleanCart = (e) => {
    e.preventDefault();
    setStockQuantity(
      stockData.parsedData?.parsedData?.map((product) => ({
        id: product.id,
        sku: product.sku,
        quantity: product.quantity,
      }))
    );
    setPosItems([]);
  };

  const handlePay = async (e) => {
    e.preventDefault();
    if (!user.hasCredentials) return;
    if (posItems.length === 0) return;

    setShowPaymentModal(true);
    const productFormData = posItems.map((item) => ({
      product_id: parseInt(item.id, 10),
      quantity: item.quantity,
    }));
    const baseAmount = posItems.reduce(
      (acc, curr) => acc + curr.quantity * curr.unitPrice,
      0
    );
    const formData = {
      baseAmount,
      discount,
      merchant_id: parseInt(user.merchantId, 10),
      transactionProducts: productFormData,
    };

    const endpoint = 'transactions';
    const method = 'POST';

    await executeAPIAction(endpoint, method, formData);
  };

  return (
    <div className="h-full flex m-auto">
      <div className="h-full w-full flex flex-direction-row gap-5 pb-4 pt-4">
        {showPaymentModal && (
          <PaymentModal
            paymentData={responseData}
            setShowModal={setShowPaymentModal}
            loading={loadingPayment}
            paymentSwal={paymentSwal}
            setPosItems={setPosItems}
          />
        )}
        <Pos
          posItems={posItems}
          setPosItems={setPosItems}
          handleQuantityChange={handleQuantityChange}
          handleDeleteClick={handleDeleteClick}
          discount={discount}
          setDiscount={setDiscount}
          handlePay={handlePay}
          handleCleanCart={handleCleanCart}
          loadingPayment={loadingPayment}
        />
        <Products
          stock={stockData?.parsedData?.parsedData || []}
          pagination={stockData?.metadata || {}}
          stockQuantity={stockQuantity}
          loading={loading}
          error={error || categoriesError}
          categories={categories}
          handleCardClick={handleCardClick}
          setStockParams={setQueryParams}
        />
      </div>
    </div>
  );
};

export default Sales;
