import { useState } from 'react';
import Input from '../../components/Shared/Input';
import Footer from '../../components/Footer/Footer';
import useAuth from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import Spinner from '../../components/Shared/Spinner';

const Auth = () => {
  const { user, login, isLoading, authError } = useAuth();
  const [inputs, setInputs] = useState({ email: '', password: '' });

  if (user !== null) {
    return <Navigate to="/pos" />;
  }

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'text') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (type === 'password') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login(inputs);
  };

  return (
    <main className={'flex flex-col h-screen w-full bg-slate-100'}>
      <div className={'flex w-full pl-3 pr-3 h-full'}>
        <div className="auth-container m-auto flex p-10 bg-white w-2/6 h-3/6 rounded-md shadow-md">
          <div className="w-full m-auto">
            <div id="header" className="w-full mb-4">
              <div id="title" className="w-full text-center text-3xl mb-2">
                <span className="font-system-ui font-bold">Pago</span>
                <span className="font-system-ui font-bold text-[#00AAFF]">
                  Hub
                </span>
              </div>
              <div id="title" className="w-full text-2xl mb-2">
                Bienvenido a tu punto de venta
              </div>
              <div id="subtitle" className="w-full text-md text-gray-400">
                Inicia sesión o contactanos para crear tu cuenta
              </div>
            </div>
            <div id="form" className="w-full">
              {!isLoading && (
                <form id="login" onSubmit={handleSubmit}>
                  <div className="w-full my-3">
                    <label
                      htmlFor="sku"
                      className="block mb-2 text-sm font-semibold text-gray-900"
                    >
                      Correo electrónico
                    </label>
                    <Input
                      placeholder="Ingresa tu email"
                      value={inputs.email}
                      name="email"
                      id="email"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full my-3">
                    <label
                      htmlFor="sku"
                      className="block mb-2 text-sm font-semibold text-gray-900"
                    >
                      Contraseña
                    </label>
                    <Input
                      type="password"
                      placeholder="Ingresa tu contraseña"
                      value={inputs.password}
                      name="password"
                      id="password"
                      onChange={handleChange}
                    />
                  </div>
                  {authError && (
                    <div id="error" className="text-red-500">
                      {authError}
                    </div>
                  )}
                  <div className="w-full mt-6 flex">
                    <button
                      disabled={isLoading}
                      type="submit"
                      className={`btn btn-light ${
                        isLoading ? 'btn-inverse' : 'btn-default'
                      } waves-effect waves-light py-2 w-full m-auto`}
                    >
                      Enviar
                    </button>
                  </div>
                </form>
              )}
              {isLoading && (
                <div className="m-auto">
                  <Spinner />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default Auth;
