import currencyFormat from '../../utils/currencyFormat';

const ItemCard = (props) => {
  const {
    sku,
    name,
    tooltip,
    needTooltip,
    category,
    price,
    stock,
    handleClick,
  } = { ...props };

  return (
    <div
      className={`
        max-w-sm h-full flex flex-col rounded overflow-hidden
        shadow-md px-4 justify-between
        ${stock > 0 ? 'hover:bg-gray-100 cursor-pointer' : 'bg-gray-200'} 
        select-none
      `}
      disabled={stock <= 0}
      onClick={handleClick}
    >
      <div className="px-2 py-2">
        <p className="text-gray-500 text-xs">
          #{sku} - {category}
        </p>
        <div className="font-bold text-md group">
          <span>{needTooltip ? tooltip : name}</span>
          {needTooltip && (
            <span
              className="absolute hidden group-hover:flex
              -mt-14 translate-y-full w-48 px-2 py-1
              bg-gray-700 rounded-lg text-center text-white text-sm"
            >
              {name}
            </span>
          )}
        </div>
      </div>
      <div className="w-full px-2 pb-2 align-middle">
        <hr className="w-full my-1 mb-2" />
        <div className="text-xs">Disponible: {stock}</div>
        <div className="text-green-600">{currencyFormat(price)}</div>
      </div>
    </div>
  );
};

export default ItemCard;
