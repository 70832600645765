const Footer = () => {
  const now = new Date();

  return (
    <div className="w-full bg-gray-50 text-center align-middle h-8">
      <span className="text-sm">{now.getFullYear()} - </span>
      <a href="https://www.pagohub.cl">
        <span className="mr-2 text-sm">powered by </span>
        <span className="font-system-ui font-bold">Pago</span>
        <span className="font-system-ui font-bold text-[#00AAFF]">Hub</span>
      </a>
      <span className="ml-1">&#169;</span>
    </div>
  );
};

export default Footer;
