import POS from 'transbank-pos-sdk-web';
import PropTypes from 'prop-types';
import ModalTemplate from '../layouts/ModalTemplate';
import { useEffect, useState } from 'react';
import usePOS from '../../hooks/usePOS';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ModalConnectToPOS = (props) => {
  const { setShowModal } = props;
  const { setActivePort, connected, setConnected } = usePOS();
  const [ports, setPorts] = useState([]);
  const posConnectionSwal = withReactContent(Swal);

  useEffect(() => {
    if (connected) {
      setShowModal(false);
    }
  }, [connected]);

  const autoconnect = (e) => {
    e.preventDefault();

    POS.autoconnect().then((port) => {
      setConnected('/dev/tty.wlan-debug');
      if (port === false) {
        posConnectionSwal.fire('No se encontró ningún POS', '', 'error');
        return;
      }
      setConnected(port.path);
      // this.$emit('connected', port.path);
      posConnectionSwal.fire(
        'Conectado',
        'Dispositivo detectado y conectado: ' + port.path,
        'success'
      );
      setShowModal(false);
    });
  };

  const listPorts = (e) => {
    e.preventDefault();

    POS.getPorts()
      .then((ports) => {
        setPorts(ports);
      })
      .catch(() => {
        posConnectionSwal.fire(
          'No se pudo obtener puertos.',
          '¿Está corriendo el agente Transbank POS?',
          'error'
        );
      });
  };

  const handleSetActivePort = async (e, port) => {
    e.preventDefault();

    setActivePort(port);
    if (connected) {
      POS.closePort();
    }
    POS.openPort(port)
      .then((result) => {
        if (result === true) {
          posConnectionSwal.fire('Conectado satisfactoriamente', '', 'success');
          setConnected(port.path);
          setShowModal(false);
          // this.$emit('connected', port);
        } else {
          posConnectionSwal.fire('No conectado', '', 'error');
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <ModalTemplate
      title="Conectar con POS Integrado"
      setShowModal={setShowModal}
      closeOnOutsideClick={false}
    >
      <div className="flex w-full flex-col items-center justify-center p-6">
        <div className="mx-auto flex flex-col">
          <span className="text-xl font-thin mb-4 text-red-500">
            POS: Desconectado
          </span>
          <div className="flex">
            <button
              onClick={(e) => autoconnect(e)}
              className="flex-1 m-2 btn-default p-4 rounded shadow outline-none"
            >
              Descubrir y conectar
            </button>
            <button
              onClick={(e) => listPorts(e)}
              className="flex-1 m-2 bg-blue-500 hover:opacity-75 p-4 rounded shadow outline-none text-white"
            >
              Listar puertos
            </button>
          </div>
        </div>

        {!connected && ports.length > 0 && (
          <div className="mt-10">
            <hr />
            <h3 className="mx-2 font-bold text-lg">Puertos disponibles: </h3>
            <p className="mx-2 mb-2">
              Encuentra el puerto en el que está conectado el POS y haz click
              sobre el para conectar
            </p>
            {!connected &&
              ports.map((port) => {
                return (
                  <button
                    key={port}
                    className="bg-blue-500 m-2 p-4 rounded shadow outline-none hover:opacity-75 text-white"
                    onClick={(e) => handleSetActivePort(e, port.path)}
                  >
                    {port.path}
                  </button>
                );
              })}
          </div>
        )}
      </div>
    </ModalTemplate>
  );
};

ModalConnectToPOS.propTypes = {
  setShowModal: PropTypes.func,
};

export default ModalConnectToPOS;
