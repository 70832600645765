const Chip = (props) => {
  const { className = '', title, selected, handleClick } = { ...props };
  return (
    <div
      className={`
      flex justify-center items-center px-4 py-3 border select-none
      border-gray-300 rounded-md ${!selected && 'hover:bg-gray-100'} text-base
      text-gray-700 font-medium ${!selected && 'cursor-pointer'} ${
        selected && 'bg-gray-200'
      } ${className}`}
      onClick={handleClick}
    >
      <div className="flex-initial max-w-full leading-none text-sm font-normal">
        {title}
      </div>
    </div>
  );
};

export default Chip;
