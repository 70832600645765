import { GridLoader } from 'react-spinners';

function Spinner(props) {
  const {
    message,
    type = 'Grid',
    color = '#288DC5',
    size = null,
  } = { ...props };

  return (
    <div>
      <div
        style={{
          padding: '15px',
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        {type === 'Grid' && <GridLoader size={size || 20} color={color} />}
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          margin: 'auto',
        }}
      >
        {message}
      </div>
    </div>
  );
}

export default Spinner;
