import PropTypes from 'prop-types';

import {
  faCircle,
  faCircleXmark,
  faRss,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import usePOS from '../../hooks/usePOS';

const Control = (props) => {
  const { setShowConnectToPOSModal, setShowPOSActionsMenuModal } = props;
  const { connected, socketConnected } = usePOS();

  const handlePOSButtonClick = (e) => {
    e.preventDefault();
    if (connected) {
      setShowPOSActionsMenuModal(true);
      return;
    }
    setShowConnectToPOSModal(true);
  };

  return (
    <div className="flex flex-direction-row gap-4">
      <div id="header-control" className="flex flex-col">
        <div className="text-end">
          Conexión con el agente:{' '}
          {socketConnected ? (
            <FontAwesomeIcon icon={faCircle} color="green" />
          ) : (
            <FontAwesomeIcon icon={faCircleXmark} color="red" />
          )}
        </div>
        <div className="text-end">
          Conexión con POS Integrado:{' '}
          {connected ? (
            <FontAwesomeIcon icon={faCircle} color="green" />
          ) : (
            <FontAwesomeIcon icon={faCircleXmark} color="red" />
          )}
        </div>
      </div>
      <div className="group">
        <button
          type="button"
          className={`btn btn-light ${
            connected ? 'btn-default' : 'btn-warning'
          } waves-effect waves-light py-3 px-2`}
          id="cashier"
          onClick={handlePOSButtonClick}
        >
          <FontAwesomeIcon icon={faRss} color={connected ? 'lightGreen' : ''} />
        </button>
        <span
          className="absolute hidden group-hover:flex -mt-14 -ml-40 translate-y-full
                    w-48 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm"
        >
          {connected
            ? 'Menú de acciones de POS Integrado'
            : 'Conectar con POS Integrado'}
        </span>
      </div>
    </div>
  );
};

Control.propTypes = {
  setShowConnectToPOSModal: PropTypes.func,
  setShowPOSActionsMenuModal: PropTypes.func,
};

export default Control;
