import PropTypes from 'prop-types';

import transactionStatusDictionary from '../../middlewares/dictionaries/transactionStatusDictionary';
import dateConverter from '../../utils/dateConverter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faMoneyBill } from '@fortawesome/free-solid-svg-icons';
import Spinner from '../Shared/Spinner';
import { useEffect, useState } from 'react';
import usePOS from '../../hooks/usePOS';

const PaymentContainer = (props) => {
  const { paymentIntentToken, user, paymentData, loading, paymentSwal } = props;
  const {
    socketConnected,
    connected,
    executePaymentGatewayPaySaleOnPOS,
    executePaymentGatewayExitPayment,
  } = usePOS();
  const [ticketData, setTicketData] = useState(null);
  const [salesResponse, setSalesResponse] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  // console.log(paymentData, ticketData);

  useEffect(() => {
    if (loading) return;
    if (paymentData === null) return;
    setTicketData(paymentData);
  }, [loading, paymentData]);

  const handlePaySale = async (e) => {
    e.preventDefault();
    setActionLoading(true);
    console.log(paymentSwal);

    await executePaymentGatewayPaySaleOnPOS(
      paymentIntentToken,
      setActionLoading,
      paymentSwal,
      salesResponse,
      setSalesResponse,
      ticketData,
      () => {} // setPosItems
    );
    setActionLoading(false);
  };

  const handleExitPayment = async (e) => {
    e.preventDefault();

    setActionLoading(true);
    try {
      await executePaymentGatewayExitPayment(
        paymentIntentToken,
        null,
        setActionLoading,
        ticketData
      );
    } catch (e) {
      console.log(e);
    }
    setActionLoading(false);

    if (ticketData.return_url) {
      window.location = ticketData.return_url;
    }
  };

  if (loading || !ticketData) {
    return (
      <div className="flex flex-col items-start max-w-2xl justify-between py-2 border-b border-solid border-slate-200 rounded-t">
        <h3 className="text-xl font-semibold">Gestión de transacción</h3>
        <div className="w-96 p-4 flex">
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-start max-w-2xl justify-between py-2 border-b border-solid border-slate-200 rounded-t">
      <h3 className="text-xl font-semibold">Gestión de transacción</h3>
      <div className="w-96 p-4">
        <div className="w-full">
          <div className="p-1">
            <span className="font-bold">Número de ticket: </span>
            {ticketData.ticket}
          </div>
          <div className="p-1">
            <span className="font-bold">Estado: </span>
            {transactionStatusDictionary(ticketData.status)}
          </div>
          <div className="p-1">
            <span className="font-bold">Cajero: </span>
            {user.email}
          </div>
          <div className="p-1">
            <span className="font-bold">Fecha: </span>
            {dateConverter(ticketData.created_at)}
          </div>
          <hr className="py-1" />
          <div id="products" className="payment-items-box p-1 w-full">
            {/* header */}
            <div className="p-1 grid grid-cols-3">
              <span className="font-bold">Item</span>
              <span className="font-bold">Cant.</span>
              <span className="font-bold">Precio</span>
            </div>
            {/* body */}
            {ticketData.direct_payment && (
              <div className="p-1 grid grid-cols-3">
                <span className="break-words">Venta directa</span>
                <span>1</span>
                <span>{ticketData.baseAmount}</span>
              </div>
            )}
            {!ticketData.direct_payment &&
              ticketData.transactionProducts.map((transactionProduct) => {
                return (
                  <div
                    key={transactionProduct.id}
                    className="p-1 grid grid-cols-3"
                  >
                    <span className="break-words">
                      {transactionProduct.product.name}
                    </span>
                    <span>{transactionProduct.quantity}</span>
                    <span>
                      {transactionProduct.product.price *
                        transactionProduct.quantity}
                    </span>
                  </div>
                );
              })}
          </div>
          <hr className="py-1" />
          <div id="Amounts" className="p-1 w-full">
            <div className="p-1 grid grid-cols-2">
              <span className="font-bold">Monto total:</span>
              <span>${ticketData.baseAmount}</span>
            </div>
            <div className="p-1 grid grid-cols-2">
              <span className="font-bold">Descuento:</span>
              <span>${ticketData.discount}</span>
            </div>
            <div className="p-1 grid grid-cols-2 text-xl">
              <span className="font-bold">Total:</span>
              <span>${ticketData.amount}</span>
            </div>
          </div>
        </div>
        <div className="w-full mt-3 grid grid-cols-4 gap-3">
          <button
            id="cancelButton"
            type="button"
            className={`btn ${actionLoading ? 'btn-inverse' : 'btn-danger'} ${
              actionLoading ? 'cursor-default' : ''
            } waves-effect waves-light py-2 col-span-1`}
            onClick={handleExitPayment}
            disabled={actionLoading}
          >
            <FontAwesomeIcon icon={faBan} />
          </button>

          <button
            type="button"
            id="payButton"
            className={`btn ${actionLoading ? 'btn-inverse' : 'btn-success'} ${
              actionLoading ? 'cursor-default' : ''
            } waves-effect waves-light py-2 px-3 col-span-3`}
            onClick={handlePaySale}
            disabled={actionLoading || !socketConnected || !connected}
          >
            {actionLoading ? (
              <Spinner size={5} message="Procesando pago en POS" />
            ) : (
              <>
                <FontAwesomeIcon icon={faMoneyBill} className="mr-2" />
                {!socketConnected || !connected
                  ? 'No hay conexión con POS'
                  : 'Pagar con POS'}
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

PaymentContainer.propTypes = {
  paymentIntentToken: PropTypes.string,
  user: PropTypes.object,
  setShowModal: PropTypes.func,
  paymentData: PropTypes.object,
  loading: PropTypes.bool,
  paymentSwal: PropTypes.any,
  navigate: PropTypes.func,
};

export default PaymentContainer;
