import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// hooks
import usePosAPIAction from '../../hooks/usePosAPIAction';

// components
import TableComponent from '../Shared/TableComponent';
import Spinner from '../Shared/Spinner';

// icons
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

const UsersTable = (props) => {
  const { data, headers, pagination, setQueryParams, refetch } = {
    ...props,
  };
  const { loading, executeAPIAction } = usePosAPIAction();

  const activateUser = async (id) => {
    if (loading) return;
    const endpoint = `users/${id}/activate`;
    const method = 'PUT';

    const success = await executeAPIAction(endpoint, method);
    if (success) {
      refetch();
    }
  };

  const deactivateUser = async (id) => {
    if (loading) return;
    const endpoint = `users/${id}/deactivate`;
    const method = 'PUT';

    const success = await executeAPIAction(endpoint, method);
    if (success) {
      refetch();
    }
  };

  const setAction = (row) => {
    if (!row.active) {
      return (
        <td key={`${row.id}-action`} className="p-2">
          <button
            type="button"
            className="btn btn-light btn-success waves-effect waves-light py-1 px-2 group"
            id="delete"
            onClick={() => activateUser(row.id)}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span
              className="absolute hidden group-hover:flex ml-4
              -mt-14 translate-y-full w-36 px-2 py-1
              bg-gray-700 rounded-lg text-center text-white text-sm"
            >
              Activar usuario
            </span>
          </button>
        </td>
      );
    } else {
      return (
        <td key={`${row.id}-action`} className="p-2">
          <button
            type="button"
            className="btn btn-light btn-danger waves-effect waves-light py-1 px-2 group"
            id="delete"
            onClick={() => deactivateUser(row.id)}
          >
            {loading && <Spinner />}
            {!loading && <FontAwesomeIcon icon={faXmark} />}
            <span
              className="absolute hidden group-hover:flex ml-4
              -mt-14 translate-y-full w-36 px-2 py-1
              bg-gray-700 rounded-lg text-center text-white text-sm"
            >
              Desactivar usuario
            </span>
          </button>
        </td>
      );
    }
  };

  return (
    <div className="w-full">
      <TableComponent
        withActions
        setAction={setAction}
        pagination={pagination}
        paginationSizeOptions={[10]}
        title="Usuarios"
        data={data}
        headers={headers}
        rowKey="id"
        setQueryParams={setQueryParams}
      />
    </div>
  );
};

export default UsersTable;
