const parseCategory = (category) => {
  const { id, name, active } = category;

  return {
    id,
    name,
    active,
    parsedActive: active ? 'Si' : 'No',
  };
};

const parseCategoriesRequest = (apiData) => {
  const { data, metadata } = apiData;

  const headers = [
    { attrName: 'id', name: 'id' },
    { attrName: 'name', name: 'Nombre' },
    { attrName: 'parsedActive', name: 'Activo' },
    { attrName: 'clickAction', name: 'Acción' },
  ];

  const parsedData = {
    headers,
    parsedData: data.map((category) => parseCategory(category)),
  };

  return {
    parsedData,
    metadata: {
      current: parseInt(metadata.page || 0, 10),
      size: parseInt(metadata.size || 1, 10),
      count: Math.ceil(metadata.count / parseInt(metadata.size || 1, 10)),
    },
  };
};

export default parseCategoriesRequest;
