import React from 'react';

const Table = (props) => {
  const { headers, data, rowKey } = { ...props };

  const createRowKey = (row, key) => {
    if (typeof key === 'string') {
      return `${row[key]}-tr`;
    }
    if (Array.isArray(key)) {
      return `${row[key[0]] + row[key[1]]}-tr`;
    }
    return `${row[0]}-tr`;
  };

  const getBGColor = (i) => {
    return i % 2 === 0 ? 'bg-white' : 'bg-neutral-100';
  };

  const isUrl = (value) => typeof value === 'object' && value?.type === 'url';
  const isPath = (value) => typeof value === 'object' && value?.type === 'path';

  return (
    <table className="table-data w-full min-w-max table-auto h-full text-left text-black">
      <thead>
        <tr key="table-headers">
          {headers.map((header) => (
            <th
              key={header.attrName}
              className="border-b border-blue-gray-100 bg-blue-gray-50 p-2"
            >
              <div className={'table-header-content'}>{header.name}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="h-full overflow-auto">
        {data.map((row, i) => (
          <tr
            key={createRowKey(row, rowKey)}
            style={{ backgroundColor: row.color ? row.color : '' }}
            className={`even:bg-blue-gray-50/50 ${getBGColor(i)}`}
          >
            {headers.map((header) => {
              if (isUrl(row[header.attrName])) {
                return (
                  <td
                    key={`${row[header.attrName]}-${header.attrName}`}
                    style={{ textAlign: 'center' }}
                    className="p-2"
                  >
                    <img
                      src={row[header.attrName].file}
                      alt={`Logo ${row.name}`}
                      style={{
                        maxWidth: '100px',
                        maxHeight: '100px',
                        width: 'auto',
                        height: 'auto',
                      }}
                    />
                  </td>
                );
              }
              if (isPath(row[header.attrName])) {
                return (
                  <td
                    key={`${row[header.attrName]}-${header.attrName}`}
                    style={{ textAlign: 'center' }}
                    className="p-2"
                  >
                    {row[header.attrName].file}
                  </td>
                );
              }
              return (
                <td
                  key={`${row[header.attrName]}-${header.attrName}`}
                  className="p-2"
                >
                  {row[header.attrName]}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
