import { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// hooks
import useComponentVisible from '../../hooks/useComponentVisible';
import useAuth from '../../hooks/useAuth';
import useMerchant from '../../hooks/useMerchant';
import usePOS from '../../hooks/usePOS';

// components
import Select from '../Shared/Select';

// icons
import {
  faCashRegister,
  faCreditCard,
  faRightFromBracket,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';

// styles
import '../../styles/sidebar.css';

const SideBar = (props) => {
  const {
    showSideBar,
    setShowSideBar,
    view,
    setView,
    setCloseModalType,
    setShowCloseDayModal,
  } = props;
  const { connected } = usePOS();
  const { user, merchants, logout } = useAuth();
  const { selectedMerchant, setSelectedMerchant } = useMerchant();
  const [isHover, setIsHover] = useState({
    Cashier: false,
    Transactions: false,
    Users: false,
  });
  const merchant =
    selectedMerchant &&
    merchants.filter((merchant) => merchant.id === user.merchantId)[0];

  const { ref, isComponentVisible } = useComponentVisible(true);

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedMerchant(value);
  };

  const handleMouseEnter = (name) => {
    setIsHover((values) => ({
      ...values,
      [name]: true,
    }));
  };
  const handleMouseLeave = (name) => {
    setIsHover((values) => ({
      ...values,
      [name]: false,
    }));
  };

  const handleOutsideClick = (e) => {
    if (isComponentVisible) return;
    e.preventDefault();
    setShowSideBar(false);
  };

  const handleClick = (e, viewName) => {
    e.preventDefault();
    setView(viewName);
    setShowSideBar(false);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    if (connected) {
      setCloseModalType('logout');
      setShowCloseDayModal(true);
    } else {
      logout();
    }
    setShowSideBar(false);
  };

  const sideBarOptions = [
    {
      viewName: 'Cashier',
      icon: faCashRegister,
      displayName: 'Cajero',
      allowedRoles: [],
      invalidRoles: [],
    },
    {
      viewName: 'Transactions',
      icon: faCreditCard,
      displayName: 'Transacciones',
      allowedRoles: [],
      invalidRoles: [],
    },
    {
      viewName: 'Users',
      icon: faUsers,
      displayName: 'Usuarios',
      allowedRoles: [],
      invalidRoles: ['caja'],
    },
  ];

  return (
    <>
      <div
        id="pos-sidebar"
        className={`${
          showSideBar ? 'open' : 'closed'
        } transition-all duration-300 transform fixed top-0 start-0 bottom-0 z-[60] w-80 bg-white border-e border-gray-200 pt-7 pb-10 overflow-y-auto end-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300`}
        ref={ref}
      >
        <div className="flex flex-col justify-between h-full">
          <div>
            <div className="w-full mb-5">
              <p className="px-4 text-2xl font-extrabold">Punto de venta</p>
            </div>

            <div className="my-4 px-4">
              {user.roles.includes('super_admin') && (
                <Select
                  id="merchant"
                  name="merchant"
                  onChange={handleChange}
                  value={selectedMerchant || user.merchantId}
                  placeholder="Selecciona un cliente"
                  options={merchants}
                  withoutBlank
                />
              )}
              {!user.roles.includes('super_admin') && <p>{merchant?.name}</p>}
            </div>

            {/* Navigation */}
            <nav className="hs-accordion-group p-0 w-full flex flex-col flex-wrap">
              <ul className="space-y-0.5">
                {sideBarOptions
                  .filter((option) => {
                    if (option.allowedRoles.length > 0) {
                      return (
                        user.roles.filter((role) =>
                          option.allowedRoles.includes(role)
                        ).length > 0
                      );
                    }
                    if (option.invalidRoles.length > 0) {
                      return (
                        user.roles.filter((role) =>
                          option.invalidRoles.includes(role)
                        ).length === 0
                      );
                    }
                    return true;
                  })
                  .map((option) => (
                    <li key={option.viewName}>
                      <button
                        className="w-full text-blue-400 flex items-center gap-x-3.5 py-4 px-2.5 text-md border-y-2"
                        style={{
                          backgroundColor:
                            view === option.viewName
                              ? '#288DC5'
                              : isHover[option.viewName]
                              ? 'rgb(243 244 246)'
                              : 'white',
                          color: view === option.viewName ? 'white' : 'black',
                          borderColor:
                            view === option.viewName ? '#288DC5' : '',
                        }}
                        onClick={(e) => handleClick(e, option.viewName)}
                        onMouseEnter={() => handleMouseEnter(option.viewName)}
                        onMouseLeave={() => handleMouseLeave(option.viewName)}
                        disabled={view === option.viewName}
                      >
                        <FontAwesomeIcon icon={option.icon} />
                        {option.displayName}
                      </button>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>

          <div className="flex mr-6">
            <button
              id="log-out"
              type="button"
              className="btn btn-warning waves-effect waves-light mx-auto py-2 px-3 pr-2"
              onClick={handleLogoutClick}
            >
              <span className="btn-label !mr-2">
                <FontAwesomeIcon icon={faRightFromBracket} />
              </span>
              Cerrar sesión
            </button>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-[40] bg-black"
        style={{ display: showSideBar ? 'block' : 'none' }}
        onClick={handleOutsideClick}
      ></div>
    </>
  );
};

SideBar.propTypes = {
  showSideBar: PropTypes.bool,
  setShowSideBar: PropTypes.func,
  view: PropTypes.string,
  setView: PropTypes.func,
  setCloseModalType: PropTypes.func,
  setShowCloseDayModal: PropTypes.func,
};

export default SideBar;
