import {
  faCartShopping,
  faCheck,
  faMoneyBill,
  faX,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Input from '../Shared/Input';
import { useState } from 'react';
import formatText from '../../utils/formatText';
import currencyFormat from '../../utils/currencyFormat';
import usePOS from '../../hooks/usePOS';
import useAuth from '../../hooks/useAuth';

const Pos = (props) => {
  const {
    posItems,
    handleDeleteClick,
    handleQuantityChange,
    discount,
    setDiscount,
    handlePay,
    handleCleanCart,
  } = { ...props };
  const [barCodeInput, setBarcodeInput] = useState('');
  const { connected } = usePOS();
  const { user } = useAuth();

  const handleBarCodeChange = (e) => {
    const { value } = e.target;
    setBarcodeInput(value);
  };

  const handleDiscountChange = (e) => {
    const { value } = e.target;
    const actualAmount = posItems.reduce(
      (acc, curr) => acc + curr.quantity * curr.unitPrice,
      0
    );
    if (value > actualAmount) {
      setDiscount(actualAmount);
      return;
    }
    setDiscount(value || 0);
  };

  return (
    <div className="bg-white mt-auto mb-auto h-full w-2/5 rounded">
      <div className="p-6 h-full">
        <div
          id="barcode-search"
          className="mb-3 button-list w-full flex flex-row"
        >
          <div className="flex flex-row w-full align-middle">
            <div className="flex w-full m-auto">
              <Input
                placeholder="Escribelo el código del producto"
                id="skuCode"
                name="skuCode"
                className="w-10/12 barcode-input"
                value={barCodeInput}
                onChange={handleBarCodeChange}
              />
              <button
                type="button"
                className="w-2/12 btn btn-right bg-slate-100 waves-effect waves-light py-2 px-2"
                id="cashier"
              >
                <span>
                  <FontAwesomeIcon icon={faCheck} />
                </span>
              </button>
            </div>
          </div>
        </div>

        <div
          id="cart-container"
          className="relative h-10/12 overflow-y-auto mb-3"
        >
          <table
            id="cart-table"
            className="items-table table-fixed text-sm text-left text-gray-500 dark:text-gray-400"
          >
            <thead className="min-w-full text-xs border-b text-gray-700 uppercase font-bold">
              <tr>
                <th className="w-1/12 text-sm font-medium text-gray-900 px-6 py-1 text-left">
                  #
                </th>
                <th className="w-3/12 text-sm font-medium text-gray-900 px-6 py-1 text-left">
                  Item
                </th>
                <th className="w-3/12 text-sm font-medium text-gray-900 px-6 py-1 text-left">
                  Cant.
                </th>
                <th className="w-3/12 text-sm font-medium text-gray-900 px-6 py-1 text-left">
                  Precio
                </th>
                <th className="w-2/12 text-sm font-medium text-gray-900 px-6 py-1 text-left">
                  Eliminar
                </th>
              </tr>
            </thead>
            <tbody>
              {posItems.map((item) => {
                return (
                  <tr
                    key={item.sku}
                    className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100"
                  >
                    <td className="px-6 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                      {item.sku}
                    </td>
                    <td className="group text-sm text-gray-900 font-light px-6 py-1">
                      <span>{formatText(item.name)}</span>
                      <span
                        className="absolute hidden group-hover:flex -mt-14 translate-y-full
                        w-48 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm"
                      >
                        {item.name}
                      </span>
                    </td>
                    <td className="text-sm text-gray-900 font-light px-3 py-1 whitespace-nowrap">
                      <Input
                        type="number"
                        min="0"
                        className="w-full"
                        name={item.sku}
                        value={item.quantity}
                        onChange={handleQuantityChange}
                      />
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-1 whitespace-nowrap">
                      {currencyFormat(item.quantity * item.unitPrice)}
                    </td>
                    <td className="text-sm text-gray-900 font-light px-6 py-1 whitespace-nowrap">
                      <button
                        className="btn btn-danger btn-xs px-1"
                        onClick={(e) => handleDeleteClick(e, item.sku)}
                      >
                        <FontAwesomeIcon icon={faX} />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <hr />

        <div className="mt-5">
          <div className="grid grid-cols-2">
            <div className="grid grid-cols-2">
              <div>Total de Item(s):</div>
              <div>
                <span id="total">
                  {posItems.reduce((acc, curr) => acc + curr.quantity, 0)}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-2 place-items-end">
              <div>Precio:</div>
              <div>
                <span id="price">
                  {currencyFormat(
                    posItems.reduce(
                      (acc, curr) => acc + curr.quantity * curr.unitPrice,
                      0
                    )
                  )}
                </span>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-4 place-items-start mt-2">
            <div>Descuento:</div>
            <div>
              <Input
                className="py-1"
                type="number"
                id="inputDiscount"
                min="0"
                value={discount}
                onChange={handleDiscountChange}
              />
            </div>
          </div>
        </div>

        <div className="button-list flex justify-end mt-3 mb-3 gap-4">
          {/* <button
            id="print"
            type="button"
            className="btn btn-info waves-effect waves-light py-2 px-3"
          >
            <FontAwesomeIcon icon={faPrint} />
          </button> */}

          <button
            id="cancelButton"
            type="button"
            className="btn btn-default waves-effect waves-light py-2 px-3"
            onClick={handleCleanCart}
          >
            <span className="btn-label">
              <FontAwesomeIcon icon={faCartShopping} />
            </span>
            Limpiar
          </button>

          <div className="group">
            <button
              disabled={!connected || !user.hasCredentials}
              type="button"
              id="payButton"
              className="btn btn-success waves-effect waves-light py-2 px-3"
              onClick={handlePay}
            >
              <span className="btn-label">
                <FontAwesomeIcon icon={faMoneyBill} />
              </span>
              Pagar
            </button>
            {!connected && (
              <span
                className="absolute hidden group-hover:flex -mt-14 translate-y-full
                        w-64 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm"
              >
                {user.hasCredentials
                  ? 'Para realizar un pago se debe tener el POS Integrado conectado'
                  : 'Su usuario no está habilitado para realizar un pago en el sistema'}
              </span>
            )}
          </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Pos;
