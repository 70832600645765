import PropType from 'prop-types';
import { useState } from 'react';
import PaginationComponent from './PaginationComponent';
import Table from '../Tables/Table';
import TableWithActions from '../Tables/TableWithActions';
import TableItemCards from '../Tables/TableItemCards';

const TableComponent = (props) => {
  const {
    title = false,
    withActions = false,
    withItemCard = false,
    setAction = () => {},
    headers = [],
    data,
    setQueryParams,
    pagination = false,
    filterable = false,
    rowKey,
    imagePath = '',
    index,
    paginationSizeOptions = [],
    showedProducts = [],
    stockQuantity = [],
    handleCardClick = () => {},
  } = props;
  const styles = {};
  // eslint-disable-next-line no-unused-vars
  const [showColumnFilter, setShowColumnFilter] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchString, setSearchString] = useState('');

  const [filteredRows, setFilteredRows] = useState(null);

  let table = (
    <Table
      index={index}
      headers={headers}
      data={filteredRows || data}
      rowKey={rowKey}
    />
  );
  if (withActions) {
    table = (
      <TableWithActions
        index={index}
        headers={headers}
        data={filteredRows || data}
        rowKey={rowKey}
        setAction={setAction}
      />
    );
  }
  if (withItemCard) {
    table = (
      <TableItemCards
        showedProducts={showedProducts}
        stockQuantity={stockQuantity}
        handleCardClick={handleCardClick}
      />
    );
  }

  const toggleSearchBar = () => {
    if (showSearch) {
      setSearchString('');
      setFilteredRows(data);
      setShowSearch(false);
    } else {
      setSearchString('');
      setShowSearch(true);
    }
  };
  const searchTable = (event) => {
    const { value } = event.target;
    const filterRows = (val) => {
      const filtered = data.filter((row) => {
        return Object.values(row).some((entry) => {
          const parsedEntry = String(entry)
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          const parsedVal = String(val)
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
          if (typeof entry === 'string') return parsedEntry.includes(parsedVal);
          if (typeof entry === 'number')
            return String(parsedEntry).includes(parsedVal);
          return false;
        });
      });
      return filtered.length > 0 ? filtered : data;
    };
    setFilteredRows(filterRows(value));
    setSearchString(value);
  };

  return (
    <>
      {title && (
        <div className="flex flex-row justify-between">
          <span className="font-bold text-lg">{title}</span>
          <div className={styles['sections-options']}>
            {filterable && (
              <>
                <div className={styles['table-searcher_holder']}>
                  {showSearch && (
                    <input
                      type="text"
                      onChange={searchTable}
                      value={searchString}
                    />
                  )}
                  <button
                    className={`${styles.option} ${styles['option-button']}`}
                    type="button"
                    onClick={toggleSearchBar}
                    title="Búsqueda por texto"
                  >
                    <img
                      src={`${imagePath}../../assets/icons/feather/search.svg`}
                      alt="Buscar consolidado"
                    />
                  </button>
                </div>
                <button
                  className={`${styles.option} ${styles['option-button']}`}
                  onClick={() => setShowColumnFilter(true)}
                  type="button"
                  title="Filtrar Columnas"
                >
                  <img
                    src={`${imagePath}../../assets/icons/feather/check-square.svg`}
                    alt="Buscar consolidado"
                  />
                </button>
              </>
            )}
            {pagination && (
              <div className={styles.option}>
                <form action="" type="options">
                  <select
                    name="view-quantity"
                    value={pagination.size}
                    onChange={(e) =>
                      setQueryParams((prevState) => ({
                        ...prevState,
                        paginationInfo: {
                          page: 1,
                          size: Number(e.target.value),
                        },
                      }))
                    }
                    id="viewQuantity"
                  >
                    {paginationSizeOptions.length === 0 && (
                      <>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={75}>75</option>
                      </>
                    )}
                    {paginationSizeOptions.length > 0 &&
                      paginationSizeOptions.map((paginationSizeOption) => (
                        <option
                          key={paginationSizeOption}
                          value={paginationSizeOption}
                        >
                          {paginationSizeOption}
                        </option>
                      ))}
                  </select>
                </form>
              </div>
            )}
          </div>
        </div>
      )}

      <div style={{ overflowX: 'auto', width: '100%' }}>{table}</div>

      {pagination && pagination.count !== 0 ? (
        <PaginationComponent
          paginationInfo={pagination}
          setQueryParams={setQueryParams}
        />
      ) : null}
    </>
  );
};

TableComponent.propTypes = {
  title: PropType.string,
  withActions: PropType.bool,
  withItemCard: PropType.bool,
  setAction: PropType.func,
  headers: PropType.array,
  data: PropType.array,
  setQueryParams: PropType.func,
  pagination: PropType.exact({
    current: PropType.number,
    size: PropType.number,
    count: PropType.number,
  }),
  filterable: PropType.bool,
  rowKey: PropType.string,
  imagePath: PropType.string,
  index: PropType.number,
  paginationSizeOptions: PropType.array,
  showedProducts: PropType.array,
  stockQuantity: PropType.array,
  handleCardClick: PropType.func,
};

export default TableComponent;
