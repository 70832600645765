import { useState } from 'react';

// hooks
import useAuth from '../../hooks/useAuth';
import usePosApiFetch from '../../hooks/usePosAPIFetch';
import useMerchant from '../../hooks/useMerchant';

// middlewares
import parseUsers from '../../middlewares/parsers/Users/parseUsers';

// components
import Spinner from '../../components/Shared/Spinner';
import ModalCreateUser from '../../components/Users/ModalCreateUser';
import UsersHeaders from '../../components/Users/UsersHeaders';
import UsersTable from '../../components/Users/UsersTable';

const Users = () => {
  const { user, merchants } = useAuth();
  const { selectedMerchant } = useMerchant();
  const merchant =
    selectedMerchant && user.roles.includes('super_admin')
      ? merchants.filter((merchant) => merchant.id === selectedMerchant)[0]
      : merchants.filter((merchant) => merchant.id === user.merchantId)[0];
  const { apiData, loading, error, refetch, setQueryParams } = usePosApiFetch(
    'users',
    parseUsers,
    { merchantId: merchant.id, active: 'true' }
  );
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [filters, setFilters] = useState({ active: 'true' });

  const handleFilter = (e) => {
    e.preventDefault();
    setQueryParams((values) => ({
      paginationInfo: {
        ...values.paginationInfo,
        page: 1,
      },
      filters: {
        ...values.filters,
        ...filters,
      },
    }));
  };

  return (
    <div className="h-full flex p-5">
      <div className="w-full h-full grid grid-rows-6 gap-3 p-4 bg-white rounded-lg">
        {showCreateUserModal && (
          <ModalCreateUser
            setShowModal={setShowCreateUserModal}
            refetch={refetch}
          />
        )}
        <div className="row-span-1 border-b-2">
          <UsersHeaders
            filters={filters}
            setFilters={setFilters}
            handleFilter={handleFilter}
            setShowCreateUserModal={setShowCreateUserModal}
          />
        </div>
        <div className="row-span-5 gap-3">
          <div>
            {loading && (
              <div className="flex w-full h-full">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            )}
            {!loading && !error && (
              <UsersTable
                data={apiData?.parsedData?.parsedData || []}
                headers={apiData?.parsedData?.headers || []}
                pagination={apiData?.metadata || {}}
                setQueryParams={setQueryParams}
                refetch={refetch}
              />
            )}
            {!loading && error && (
              <div className="flex w-full h-full">
                <div className="m-auto">Ocurrió un error</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
