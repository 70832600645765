import { useContext, useEffect } from 'react';
import { MerchantContext } from '../context/MerchantContext';
import useAuth from './useAuth';

const useMerchant = () => {
  const { user, merchants, isLoading } = useAuth();
  const { value, setValue } = useContext(MerchantContext);

  useEffect(() => {
    if (isLoading) return;
    const selectedMerchant = merchants.filter(
      (merchant) => merchant.id === value
    );
    if (selectedMerchant.length === 0) {
      setValue(user?.merchantId);
    }
  }, [value, isLoading]);

  return {
    selectedMerchant: value,
    setSelectedMerchant: setValue,
  };
};

export default useMerchant;
