import { useEffect, useState } from 'react';
import PropType from 'prop-types';

// Components
import ModalTemplate from '../layouts/ModalTemplate';
import Spinner from '../Shared/Spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Hooks
import useAuth from '../../hooks/useAuth';
import usePOS from '../../hooks/usePOS';

// Middleware
import transactionStatusDictionary from '../../middlewares/dictionaries/transactionStatusDictionary';
import dateConverter from '../../utils/dateConverter';

// Icons
import { faBan, faMoneyBill } from '@fortawesome/free-solid-svg-icons';

const PaymentModal = (props) => {
  const { setShowModal, loading, paymentData, paymentSwal, setPosItems } =
    props;
  const { user } = useAuth();
  const { executePaySaleOnPOS, executeExitPayment } = usePOS();
  const [actionLoading, setActionLoading] = useState(false);
  const [salesResponse, setSalesResponse] = useState(null);
  const [ticketData, setTicketData] = useState(null);

  useEffect(() => {
    if (loading) return;
    if (paymentData === null) {
      setShowModal(false);
      return;
    }
    setTicketData(paymentData.data);
  }, [loading, paymentData]);

  const handlePaySale = async (e) => {
    e.preventDefault();

    await executePaySaleOnPOS(
      setActionLoading,
      paymentSwal,
      salesResponse,
      setSalesResponse,
      ticketData,
      setPosItems,
      setShowModal
    );
  };

  const handleExitPayment = async (e) => {
    e.preventDefault();

    await executeExitPayment(null, setActionLoading, setShowModal, ticketData);
  };

  if (loading || !ticketData) {
    return (
      <ModalTemplate
        withoutClose
        setShowModal={setShowModal}
        title="Gestión de pago"
      >
        <div className="w-96 p-4 flex">
          <div className="m-auto">
            <Spinner />
          </div>
        </div>
      </ModalTemplate>
    );
  }

  return (
    <ModalTemplate
      withoutClose
      closeOnOutsideClick={false}
      setShowModal={setShowModal}
      title="Gestión de pago"
    >
      <div className="w-96 p-4">
        <div className="w-full">
          <div className="p-1">
            <span className="font-bold">Número de ticket: </span>
            {ticketData.ticket}
          </div>
          <div className="p-1">
            <span className="font-bold">Estado: </span>
            {transactionStatusDictionary(ticketData.status)}
          </div>
          <div className="p-1">
            <span className="font-bold">Cajero: </span>
            {user.email}
          </div>
          <div className="p-1">
            <span className="font-bold">Fecha: </span>
            {dateConverter(ticketData.created_at)}
          </div>
          <hr className="py-1" />
          <div id="products" className="payment-items-box p-1 w-full">
            {/* header */}
            <div className="p-1 grid grid-cols-3">
              <span className="font-bold">Item</span>
              <span className="font-bold">Cant.</span>
              <span className="font-bold">Precio</span>
            </div>
            {/* body */}
            {ticketData.transactionProducts.map((transactionProduct) => {
              return (
                <div
                  key={transactionProduct.id}
                  className="p-1 grid grid-cols-3"
                >
                  <span className="break-words">
                    {transactionProduct.product.name}
                  </span>
                  <span>{transactionProduct.quantity}</span>
                  <span>
                    {transactionProduct.product.price *
                      transactionProduct.quantity}
                  </span>
                </div>
              );
            })}
          </div>
          <hr className="py-1" />
          <div id="Amounts" className="p-1 w-full">
            <div className="p-1 grid grid-cols-2">
              <span className="font-bold">Monto total:</span>
              <span>${ticketData.baseAmount}</span>
            </div>
            <div className="p-1 grid grid-cols-2">
              <span className="font-bold">Descuento:</span>
              <span>${ticketData.discount}</span>
            </div>
            <div className="p-1 grid grid-cols-2 text-xl">
              <span className="font-bold">Total:</span>
              <span>${ticketData.amount}</span>
            </div>
          </div>
        </div>
        <div className="w-full mt-3 grid grid-cols-4 gap-3">
          <button
            id="cancelButton"
            type="button"
            className={`btn ${actionLoading ? 'btn-inverse' : 'btn-danger'} ${
              actionLoading ? 'cursor-default' : ''
            } waves-effect waves-light py-2 col-span-1`}
            onClick={handleExitPayment}
            disabled={actionLoading}
          >
            <FontAwesomeIcon icon={faBan} />
          </button>

          <button
            type="button"
            id="payButton"
            className={`btn ${actionLoading ? 'btn-inverse' : 'btn-success'} ${
              actionLoading ? 'cursor-default' : ''
            } waves-effect waves-light py-2 px-3 col-span-3`}
            onClick={handlePaySale}
            disabled={actionLoading}
          >
            {actionLoading ? (
              <Spinner size={5} message="Procesando pago en POS" />
            ) : (
              <>
                <FontAwesomeIcon icon={faMoneyBill} className="mr-2" />
                Pagar
              </>
            )}
          </button>
        </div>
      </div>
    </ModalTemplate>
  );
};

PaymentModal.propTypes = {
  setShowModal: PropType.func,
  loading: PropType.bool,
  paymentData: PropType.object,
  paymentSwal: PropType.any,
  setPosItems: PropType.func,
};

export default PaymentModal;
