import PropTypes from 'prop-types';

// Components
import Input from '../Shared/Input';
import Select from '../Shared/Select';

const TransactionFilters = (props) => {
  const { connected, filters, setFilters, handleFilter, setShowPosDailySales } =
    props;
  const disabled = false;
  const today = new Date();
  const statusOptions = [
    { id: 'all', name: 'Todos' },
    { id: 'success', name: 'Completado' },
    { id: 'in_progress', name: 'En progreso' },
    { id: 'failure', name: 'Fallida' },
    { id: 'refund', name: 'Anulada' },
  ];

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'date') {
      setFilters((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (type === 'select-one') {
      setFilters((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  return (
    <div className="w-full flex justify-between">
      <div className="my-auto">
        <div className="flex flex-col gap-2">
          <span className="font-bold text-xl align-middle">Transacciones</span>
          <div className="group">
            <button
              disabled={!connected}
              type="submit"
              className={`btn btn-light ${
                !connected ? 'btn-inverse' : 'btn-default'
              } waves-effect waves-light py-1 px-5 m-auto ml-0`}
              onClick={() => setShowPosDailySales(true)}
            >
              Mostrar ventas del día en POS
            </button>
            {!connected && (
              <span
                className="absolute hidden group-hover:flex -mt-14 ml-14 translate-y-full
                    w-48 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm"
              >
                No se detecta conexión con POS Integrado
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="p-2 flex gap-3">
        <div className="flex flex-col gap-1 w-full">
          <label
            htmlFor="status"
            className="inline-block text-sm font-semibol align-middle text-gray-500"
          >
            Estado:
          </label>
          <Select
            id="status"
            name="status"
            onChange={handleChange}
            value={filters.status}
            placeholder="Selecciona una categoría"
            options={statusOptions}
            withoutBlank
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="dateFrom"
            className="inline-block text-sm font-semibol align-middle text-gray-500"
          >
            Desde:
          </label>
          <Input
            type="date"
            placeholder="Transacciones desde..."
            className="py-1"
            value={filters.dateFrom}
            name="dateFrom"
            id="dateFrom"
            max={today.toISOString().split('T')[0]}
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col gap-1">
          <label
            htmlFor="dateTo"
            className="inline-block text-sm font-semibol align-middle text-gray-500"
          >
            Hasta:
          </label>
          <Input
            type="date"
            placeholder="Transacciones hasta..."
            className="py-1"
            value={filters.dateTo}
            name="dateTo"
            id="dateTo"
            max={today.toISOString().split('T')[0]}
            min={filters.dateFrom}
            onChange={handleChange}
          />
        </div>
        <div className="mt-auto w-full flex">
          <button
            disabled={disabled}
            type="submit"
            className={`btn btn-light ${
              disabled ? 'btn-inverse' : 'btn-default'
            } waves-effect waves-light py-2 px-5 m-auto`}
            onClick={handleFilter}
          >
            Filtrar
          </button>
        </div>
      </div>
    </div>
  );
};

TransactionFilters.propTypes = {
  connected: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  filters: PropTypes.exact({
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    status: PropTypes.string,
  }),
  setFilters: PropTypes.func,
  handleFilter: PropTypes.func,
  setShowPosDailySales: PropTypes.func,
};

export default TransactionFilters;
