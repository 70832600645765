import useDictionary from './useDictionary';

const transactionStatusDictionary = (value) => {
  const dictionary = {
    in_progress: 'En progreso',
    success: 'Completado',
    failure: 'Fallida',
    refund: 'Anulada',
  };
  return useDictionary(value, dictionary);
};

export default transactionStatusDictionary;
