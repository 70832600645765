import useAuth from '../../hooks/useAuth';
import useMerchant from '../../hooks/useMerchant';
import Select from '../Shared/Select';
import ModalTemplate from '../layouts/ModalTemplate';

const ModalMerchants = (props) => {
  const { setShowModal, setSelectedMerchant } = { ...props };
  const { user, merchants } = useAuth();
  const { selectedMerchant } = useMerchant();

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedMerchant(value);
    setShowModal(false);
  };

  return (
    <ModalTemplate setShowModal={setShowModal} title={'Clientes'}>
      <div className="relative p-6 flex-auto w-96">
        <div className="w-full my-3">
          <label
            htmlFor="merchants"
            className="block mb-2 text-sm font-semibold text-gray-900"
          >
            Selecciona un cliente a ver
          </label>
          <Select
            id="merchant"
            name="merchant"
            onChange={handleChange}
            value={selectedMerchant || user.merchantId}
            placeholder="Selecciona un cliente"
            options={merchants}
            withoutBlank
          />
        </div>
      </div>
    </ModalTemplate>
  );
};

export default ModalMerchants;
