import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import useAuth from '../../hooks/useAuth';
import usePOS from '../../hooks/usePOS';
import Footer from '../../components/Footer/Footer';
import Spinner from '../../components/Shared/Spinner';
import usePosAPIAction from '../../hooks/usePosAPIAction';
import PaymentContainer from '../../components/PaymentGateway/PaymentContainer';

const PaymentGateway = () => {
  const navigate = useNavigate();
  const { user, isLoading: userLoading } = useAuth();
  const { socketConnected } = usePOS();
  const { transactionToken } = useParams();
  const {
    responseData,
    loading: loadingPayment,
    error: paymentError,
    executeAPIAction,
  } = usePosAPIAction();
  const agentSwal = withReactContent(Swal);
  const paymentSwal = withReactContent(Swal);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user && userLoading) {
      setLoading(true);
      setLoadingMessage('Verificando al usuario conectado...');
    } else if (user && loadingPayment) {
      setLoading(true);
      setLoadingMessage('Obteniendo datos de transacción...');
    } else if (user && !loadingPayment && !responseData && !paymentError) {
      setLoading(true);
      setLoadingMessage('Cargando datos de transacción...');
    } else {
      setLoading(false);
      setLoadingMessage('');
    }
  }, [userLoading, loadingPayment, setLoading, setLoadingMessage]);

  useEffect(() => {
    const getTransaction = async () => {
      const formData = {
        cash_alp_token: transactionToken,
      };

      const endpoint = 'transactions/payment/create';
      const method = 'POST';

      await executeAPIAction(endpoint, method, formData);
    };
    if (user && !userLoading) {
      getTransaction();
    }
  }, [user, userLoading]);

  useEffect(() => {
    let timerId;

    timerId = setTimeout(() => {
      if (!socketConnected) {
        agentSwal.fire({
          title: <p>No se pudo conectar con el agente Transbank POS</p>,
          html: (
            <i>
              Verifique que el agente se haya inicializado en este computador
            </i>
          ),
          icon: 'error',
        });
      }
    }, 1500);

    if (socketConnected) {
      agentSwal.close();
      return;
    }

    return () => clearTimeout(timerId);
  }, [socketConnected]);

  const handleLoginRedirect = () => {
    navigate('/');
  };

  return (
    <main className={'flex flex-col h-screen w-full bg-slate-100'}>
      <div className={'flex w-full pl-3 pr-3 h-full'}>
        <div className="auth-container m-auto flex p-10 bg-white min-h-3/6 rounded-md shadow-md transition-all">
          <div className="m-auto">
            {loading && (
              <div className="m-auto flex flex-col">
                <Spinner />
                <div id="title" className="mx-auto text-2xl mb-2">
                  {loadingMessage}
                </div>
              </div>
            )}
            {!loading && !user && (
              <div className="m-auto flex flex-col">
                <div id="title" className="mx-auto text-2xl mb-2">
                  No hay un usuario conectado.
                </div>
                <div className="mx-auto text-lg mb-2 text-gray-500">
                  Inicia sesión usando el siguiente link
                </div>
                <div className="w-full mt-6 flex">
                  <button
                    type="submit"
                    className={`btn btn-light btn-default waves-effect waves-light py-2 w-full m-auto`}
                    onClick={handleLoginRedirect}
                  >
                    Iniciar sesión
                  </button>
                </div>
              </div>
            )}
            {!loading && user && responseData?.data && (
              <PaymentContainer
                paymentIntentToken={transactionToken}
                user={user}
                paymentSwal={paymentSwal}
                paymentData={responseData.data}
                loading={loading}
              />
            )}
            {!loading && !responseData && paymentError && (
              <div className="m-auto flex flex-col">
                <div
                  id="title"
                  className="mx-auto font-black text-3xl text-primaryColor"
                >
                  {paymentError?.response?.data
                    ? `Error ${paymentError?.response?.data?.statusCode}`
                    : 'Error'}
                </div>
                <div
                  id="title"
                  className="mx-auto font-black text-3xl mb-4 text-primaryColor"
                >
                  Hubo un problema
                </div>
                <div className="mx-auto text-lg text-gray-500">
                  No se pudo continuar con la transacción,
                </div>
                <div className="mx-auto text-lg mb-4 text-gray-500">
                  inténtalo de nuevo o contacta al supervisor.
                </div>

                {paymentError?.response?.data && (
                  <div className="mx-auto text-sm mb-2 text-gray-500">
                    Descripción: {paymentError?.response?.data?.message}
                  </div>
                )}
              </div>
            )}
            {/* {!userLoading && user && (
              Load transaction wait and show
            )} */}
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
};

export default PaymentGateway;
