import PropTypes from 'prop-types';

const Input = (props) => {
  const { className, defaultWidth, ...baseProps } = { ...props };

  return (
    <input
      type="text"
      className={`${
        defaultWidth ? '' : 'w-full'
      } appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${className}`}
      {...baseProps}
    />
  );
};

Input.propTypes = {
  className: PropTypes.string,
  defaultWidth: PropTypes.bool,
  baseProps: PropTypes.object,
};

export default Input;
