import PropTypes from 'prop-types';

import Actions from './Actions';
import Control from './Control';

// Styles
import '../../styles/headers.css';

const Header = (props) => {
  const {
    setShowConnectToPOSModal,
    setShowPOSActionsMenuModal,
    setShowCloseDayModal,
    setCloseModalType,
    setShowSideBar,
    view,
    setView,
  } = { ...props };

  return (
    <div className="w-auto h-auto flex flex-direction-row gap-5 justify-between mt-4 ml-3 mr-3">
      <Actions
        setShowCloseDayModal={setShowCloseDayModal}
        setCloseModalType={setCloseModalType}
        setShowSideBar={setShowSideBar}
        view={view}
        setView={setView}
      />
      <Control
        setShowConnectToPOSModal={setShowConnectToPOSModal}
        setShowPOSActionsMenuModal={setShowPOSActionsMenuModal}
      />
    </div>
  );
};

Header.propTypes = {
  view: PropTypes.string,
  setView: PropTypes.func,
  setShowConnectToPOSModal: PropTypes.func,
  setShowPOSActionsMenuModal: PropTypes.func,
  setShowCloseDayModal: PropTypes.func,
  setCloseModalType: PropTypes.func,
  setShowSideBar: PropTypes.func,
};

export default Header;
