import formatText from '../../../utils/formatText';

const parseProduct = (product) => {
  const { id, sku, name, price, quantity, category } = product;

  return {
    id,
    sku,
    name,
    nameTooltip: formatText(name, 25),
    nameTooltipText: name,
    nameTooltipNeed: name.length >= 25,
    price,
    quantity,
    categoryId: category.id,
    category: category.name,
  };
};

const parseStockProducts = (apiData) => {
  const { data, metadata } = apiData;
  const parsedData = {
    parsedData: data.map((product) => parseProduct(product)),
  };

  return {
    parsedData,
    metadata: {
      current: parseInt(metadata.page || 0, 10),
      size: parseInt(metadata.size || 1, 10),
      count: Math.ceil(metadata.count / parseInt(metadata.size || 1, 10)),
    },
  };
};

export default parseStockProducts;
