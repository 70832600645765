import PropTypes from 'prop-types';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useComponentVisible from '../../hooks/useComponentVisible';

const ModalTemplate = (props) => {
  const {
    children,
    setShowModal,
    title,
    withoutClose = false,
    closeOnOutsideClick = true,
    zIndex = 'z-50',
    zIndexBackground = 'z-40',
  } = { ...props };
  const { ref, isComponentVisible } = useComponentVisible(true);

  const handleOutsideClick = () => {
    if (closeOnOutsideClick && !isComponentVisible) setShowModal(false);
  };

  return (
    <>
      <div
        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 ${zIndex} outline-none focus:outline-none`}
        onClick={handleOutsideClick}
      >
        <div className="relative w-auto my-6 mx-auto max-w-3xl" ref={ref}>
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-6">
            <div className="flex items-start max-w-2xl justify-between py-2 border-b border-solid border-slate-200 rounded-t">
              <h3 className="text-xl font-semibold">{title}</h3>
              {!withoutClose && (
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <FontAwesomeIcon
                    icon={faXmark}
                    color={closeOnOutsideClick ? 'gray' : 'black'}
                    opacity="0.7"
                  />
                </button>
              )}
            </div>
            {children}
          </div>
        </div>
      </div>
      <div
        className={`opacity-25 fixed inset-0 ${zIndexBackground} bg-black`}
      ></div>
    </>
  );
};

ModalTemplate.propTypes = {
  children: PropTypes.element,
  setShowModal: PropTypes.func,
  title: PropTypes.string,
  withoutClose: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  zIndex: PropTypes.string,
  zIndexBackground: PropTypes.string,
};

export default ModalTemplate;
