import Spinner from '../Shared/Spinner';

const TransactionsSummary = (props) => {
  const { data, loading } = { ...props };

  if (loading) {
    return (
      <div className="w-full h-full max-h-full p-2 overflow-y-auto flex flex-col justify-evenly">
        <div className="m-auto">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full h-full max-h-full p-2 overflow-y-auto flex flex-col justify-evenly border-r-2">
      <div className="flex">
        <div className="m-auto w-full text-xl font-bold flex flex-col flex-wrap p-6 rounded-lg bg-blue-300">
          <span className="m-auto text-center">Monto total de hoy</span>
          <span className="m-auto">${data?.dailyTotal || 0}</span>
        </div>
      </div>
      <div className="flex">
        <div className="m-auto text-xl font-bold flex flex-wrap p-6 rounded-lg bg-green-300">
          <span className="m-auto text-center">
            N° de transacciones exitosas hoy
          </span>
          <span className="m-auto">{data?.dailySuccess || 0}</span>
        </div>
      </div>
      <div className="flex">
        <div className="m-auto text-xl font-bold flex flex-wrap p-6 rounded-lg bg-red-300">
          <span className="m-auto text-center">
            N° de transacciones fallidas hoy
          </span>
          <span className="m-auto">{data?.dailyFailed || 0}</span>
        </div>
      </div>
    </div>
  );
};

export default TransactionsSummary;
