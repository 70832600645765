import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PaginationComponent = (props) => {
  const { paginationInfo, setQueryParams } = { ...props };
  const styles = {
    pagination: 'flex flex-row justify-center gap-4 mt-4',
    pages: 'flex flex-row gap-4',
    active: 'text-primaryColor font-bold',
  };
  const changePage = (newPage) => {
    if (newPage === paginationInfo.current) {
      return;
    }
    if (newPage > paginationInfo.count) {
      return;
    }
    setQueryParams((prev) => ({
      ...prev,
      paginationInfo: { ...prev.paginationInfo, page: newPage },
    }));
  };

  // First
  if (paginationInfo.current === 1) {
    return (
      <div className={styles.pagination}>
        <button
          type="button"
          className="btn-quaternary"
          onClick={() => changePage(paginationInfo.current - 1)}
          disabled
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <ul className={styles.pages}>
          <li className={`${styles.page} ${styles.active}`}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => changePage(paginationInfo.current)}
              onKeyDown={() => {}}
            >
              <a className="pagination-btn-text">
                {paginationInfo.current || '1'}
              </a>
            </div>
          </li>
          {paginationInfo.count > 1 && (
            <li className={styles.page}>
              <div
                role="button"
                tabIndex="0"
                onClick={() => changePage(paginationInfo.current + 1)}
                onKeyDown={() => {}}
              >
                <a className="pagination-btn-text">
                  {paginationInfo.current + 1}
                </a>
              </div>
            </li>
          )}
          {paginationInfo.count > 2 && (
            <li className={styles.page}>
              <div
                role="button"
                tabIndex="0"
                onClick={() => changePage(paginationInfo.current + 2)}
                onKeyDown={() => {}}
              >
                <a className="pagination-btn-text">
                  {paginationInfo.current + 2}
                </a>
              </div>
            </li>
          )}
          {paginationInfo.count > 3 && (
            <>
              <li className={styles.page}>...</li>
              <li className={styles.page}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => changePage(paginationInfo.count)}
                  onKeyDown={() => {}}
                >
                  <a className="pagination-btn-text">{paginationInfo.count}</a>
                </div>
              </li>
            </>
          )}
        </ul>
        <button
          type="button"
          className="btn-quaternary"
          onClick={() => changePage(paginationInfo.current + 1)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    );
  }

  // Lasts
  if (paginationInfo.current === paginationInfo.count) {
    return (
      <div className={styles.pagination}>
        <button
          type="button"
          className="btn-quaternary"
          onClick={() => changePage(paginationInfo.current - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </button>
        <ul className={styles.pages}>
          {paginationInfo.count > 3 && (
            <>
              <li className={styles.page}>
                <div
                  role="button"
                  tabIndex="0"
                  onClick={() => changePage(1)}
                  onKeyDown={() => {}}
                >
                  <a className="pagination-btn-text">1</a>
                </div>
              </li>
              <li className={styles.page}>...</li>
            </>
          )}
          {paginationInfo.count > 2 && (
            <li className={styles.page}>
              <div
                role="button"
                tabIndex="0"
                onClick={() => changePage(paginationInfo.current - 2)}
                onKeyDown={() => {}}
              >
                <a className="pagination-btn-text">
                  {paginationInfo.current - 2}
                </a>
              </div>
            </li>
          )}
          <li className={styles.page}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => changePage(paginationInfo.current - 1)}
              onKeyDown={() => {}}
            >
              <a className="pagination-btn-text">
                {paginationInfo.current - 1}
              </a>
            </div>
          </li>
          <li className={`${styles.page} ${styles.active}`}>
            {paginationInfo.current}
          </li>
        </ul>
        <button type="button" className="btn-quaternary" disabled>
          <FontAwesomeIcon icon={faArrowRight} />
        </button>
      </div>
    );
  }

  // Middle
  return (
    <div className={styles.pagination}>
      <button
        type="button"
        className="btn-quaternary"
        onClick={() => changePage(paginationInfo.current - 1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <ul className={styles.pages}>
        {paginationInfo.current > 2 && (
          <li className={styles.page}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => changePage(1)}
              onKeyDown={() => {}}
            >
              <a className="pagination-btn-text">{1}</a>
            </div>
          </li>
        )}
        {paginationInfo.current > 3 && <li className={styles.page}>...</li>}
        <li className={styles.page}>
          <div
            role="button"
            tabIndex="0"
            onClick={() => changePage(paginationInfo.current - 1)}
            onKeyDown={() => {}}
          >
            <a className="pagination-btn-text">{paginationInfo.current - 1}</a>
          </div>
        </li>
        <li className={`${styles.page} ${styles.active}`}>
          {paginationInfo.current}
        </li>
        <li className={styles.page}>
          <div
            role="button"
            tabIndex="0"
            onClick={() => changePage(paginationInfo.current + 1)}
            onKeyDown={() => {}}
          >
            <a className="pagination-btn-text">{paginationInfo.current + 1}</a>
          </div>
        </li>
        {paginationInfo.current < paginationInfo.count - 2 && (
          <li className={styles.page}>...</li>
        )}
        {paginationInfo.current < paginationInfo.count - 1 && (
          <li className={styles.page}>
            <div
              role="button"
              tabIndex="0"
              onClick={() => changePage(paginationInfo.count)}
              onKeyDown={() => {}}
            >
              <a className="pagination-btn-text">{paginationInfo.count}</a>
            </div>
          </li>
        )}
      </ul>
      <button
        type="button"
        className="btn-quaternary"
        onClick={() => changePage(paginationInfo.current + 1)}
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </div>
  );
};

export default PaginationComponent;
