import currencyFormat from '../../../utils/currencyFormat';
import formatText from '../../../utils/formatText';

const parseProduct = (product) => {
  const { id, sku, name, active, price, quantity, category } = product;

  return {
    id,
    sku,
    name,
    active,
    parsedActive: active ? 'Si' : 'No',
    nameTooltip: formatText(name, 20),
    nameTooltipText: name,
    nameTooltipNeed: name.length >= 20,
    price,
    parsedPrice: currencyFormat(price),
    quantity,
    categoryId: category.id,
    category: category.name,
  };
};

const parseProductsRequest = (apiData) => {
  const { data, metadata } = apiData;
  const headers = [
    { attrName: 'sku', name: 'Sku' },
    { attrName: 'nameTooltip', name: 'Nombre' },
    { attrName: 'category', name: 'Categoría' },
    { attrName: 'quantity', name: 'Cantidad' },
    { attrName: 'parsedPrice', name: 'Precio' },
    { attrName: 'parsedActive', name: 'Activo' },
    { attrName: 'clickAction', name: 'Acción' },
  ];

  const parsedData = {
    headers,
    parsedData: data.map((product) => parseProduct(product)),
  };

  return {
    parsedData,
    metadata: {
      current: parseInt(metadata.page || 0, 10),
      size: parseInt(metadata.size || 1, 10),
      count: Math.ceil(metadata.count / parseInt(metadata.size || 1, 10)),
    },
  };
};

export default parseProductsRequest;
