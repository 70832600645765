const parsePollAction = (res) => {
  let message = 'No hubo conexión con POS';
  if (res) {
    message = 'Conectado con POS';
  }
  return {
    success: res,
    message,
  };
};

const parseNormalModeAction = (res) => {
  let message = 'No hubo conexión con POS';
  if (res) {
    message = 'Cambiado el POS a modo normal';
  }
  return {
    success: res,
    message,
  };
};

const parsePOSActionResponse = (res, action) => {
  if (action === 'Poll') {
    return parsePollAction(res);
  }
  if (action === 'NormalMode') {
    return parseNormalModeAction(res);
  }
  return {
    success: false,
    message: '',
  };
};

export default parsePOSActionResponse;
