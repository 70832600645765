import { useState } from 'react';

// Hooks
import useAuth from '../../hooks/useAuth';
import useMerchant from '../../hooks/useMerchant';
import usePOS from '../../hooks/usePOS';

// Components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalProducts from '../Products/ModalProducts';
import ModalCategories from '../Categories/ModalCategories';
import ModalCreateProduct from '../Products/ModalCreateProduct';
import ModalCreateCategory from '../Categories/ModalCreateCategory';
import ModalMerchants from './ModalMerchants';

// utils
import formatText from '../../utils/formatText';

// Icons
import {
  faBarcode,
  faBars,
  faPlus,
  faRightFromBracket,
  faTableCells,
} from '@fortawesome/free-solid-svg-icons';

const Actions = (props) => {
  const { setShowCloseDayModal, setCloseModalType, setShowSideBar } = {
    ...props,
  };
  const { selectedMerchant, setSelectedMerchant } = useMerchant();
  const { connected } = usePOS();
  const { user, merchants, logout } = useAuth();
  const [showProductModal, setShowProductModal] = useState(false);
  const [showCreateProductModal, setShowCreateProductModal] = useState(false);
  const [showCategoriesModal, setShowCategoriesModal] = useState(false);
  const [showCreateCategoryModal, setShowCreateCategoryModal] = useState(false);
  const [showMerchantsModal, setShowMerchantsModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const merchant =
    selectedMerchant && user.roles.includes('super_admin')
      ? merchants.filter((merchant) => merchant.id === selectedMerchant)[0]
      : merchants.filter((merchant) => merchant.id === user.merchantId)[0];

  const handleLogoutClick = (e) => {
    e.preventDefault();
    if (connected) {
      setCloseModalType('logout');
      setShowCloseDayModal(true);
    } else {
      logout();
    }
  };

  const handleOpenSideBar = (e) => {
    e.preventDefault();
    setShowSideBar(true);
  };

  return (
    <>
      <div id="header-actions" className="flex flex-direction-row gap-4">
        <div className="inline-flex">
          <button
            type="button"
            className="btn btn-light btn-white waves-effect waves-light py-2 px-2"
            id="cashier"
            onClick={handleOpenSideBar}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>

          <div className="flex flex-col px-2 mx-2">
            <span id="loggedin-user" className="text-sm group">
              {formatText(merchant?.name, 15)}
              {(merchant?.name || '').length >= 15 && (
                <span
                  className="absolute hidden group-hover:flex
                              -mt-6 translate-y-full w-64 px-2 py-1
                              bg-gray-700 rounded-lg text-center text-white text-sm"
                >
                  {merchant?.name}
                </span>
              )}
            </span>
            <span id="loggedin-user" className="font-bold group">
              {formatText(user.username, 12)}
              {(user?.username || '').length >= 12 && (
                <span
                  className="absolute hidden group-hover:flex
                              -mt-6 translate-y-full w-64 px-2 py-1
                              bg-gray-700 rounded-lg text-center text-white text-sm"
                >
                  {user?.username}
                </span>
              )}
            </span>
          </div>

          <button
            id="log-out"
            type="button"
            className="btn btn-right btn-warning waves-effect waves-light py-2 px-3"
            onClick={handleLogoutClick}
          >
            <FontAwesomeIcon icon={faRightFromBracket} />
          </button>
        </div>

        {(user.roles.includes('admin') ||
          user.roles.includes('super_admin')) && (
          <div className="btn-group p_one mt-auto">
            <button
              id="product-modal"
              type="button"
              className="btn btn-default btn-left waves-effect waves-light py-2 px-3 pr-2"
              onClick={() => setShowProductModal(!showProductModal)}
            >
              <span className="btn-label !mr-2">
                <FontAwesomeIcon icon={faBarcode} />
              </span>
              Productos
            </button>
            <button
              id="newProductModal"
              type="button"
              className="btn btn-right btn-warning waves-effect waves-light py-2 px-3"
              onClick={(e) => {
                e.preventDefault();
                setSelectedProduct(null);
                setShowCreateProductModal(!showCreateProductModal);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        )}

        {(user.roles.includes('admin') ||
          user.roles.includes('super_admin')) && (
          <div className="btn-group p_two mt-auto">
            <button
              id="categoryModal"
              type="button"
              className="btn btn-default btn-left waves-effect waves-light py-2 px-3 pr-2"
              onClick={() => setShowCategoriesModal(!showCategoriesModal)}
            >
              <span className="btn-label !mr-2">
                <FontAwesomeIcon icon={faTableCells} />
              </span>
              Categorías
            </button>
            <button
              id="newCategoryModal"
              type="button"
              className="btn btn-right btn-warning waves-effect waves-light py-2 px-3"
              onClick={(e) => {
                e.preventDefault();
                setSelectedCategory(null);
                setShowCreateCategoryModal(!showCreateCategoryModal);
              }}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        )}
      </div>
      {showProductModal && (
        <ModalProducts
          setSelectedProduct={setSelectedProduct}
          setShowCreateProductModal={setShowCreateProductModal}
          setShowModal={setShowProductModal}
        />
      )}
      {showCategoriesModal && (
        <ModalCategories
          setShowModal={setShowCategoriesModal}
          setShowCreateCategoryModal={setShowCreateCategoryModal}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {showCreateProductModal && (
        <ModalCreateProduct
          product={selectedProduct}
          setShowModal={setShowCreateProductModal}
        />
      )}
      {showCreateCategoryModal && (
        <ModalCreateCategory
          category={selectedCategory}
          setShowModal={setShowCreateCategoryModal}
        />
      )}
      {showMerchantsModal && (
        <ModalMerchants
          setShowModal={setShowMerchantsModal}
          setSelectedMerchant={setSelectedMerchant}
        />
      )}
    </>
  );
};

export default Actions;
