import { useState } from 'react';
import PropType from 'prop-types';
import POS from 'transbank-pos-sdk-web';

// Components
import ModalTemplate from '../layouts/ModalTemplate';
import Spinner from '../Shared/Spinner';

// Hooks
import usePosAPIAction from '../../hooks/usePosAPIAction';

// Middlewares
import transactionStatusDictionary from '../../middlewares/dictionaries/transactionStatusDictionary';
import dateConverter from '../../utils/dateConverter';
import useAuth from '../../hooks/useAuth';
import Input from '../Shared/Input';

const ModalTransaction = (props) => {
  const {
    connected,
    socketConnected,
    executePaySaleOnPOS,
    executeExitPayment,
    ticketData,
    setShowModal,
    refundSwal,
    refetch,
  } = props;
  const { executeAPIAction } = usePosAPIAction();
  const [posResponse, setPosResponse] = useState(null);
  const [missingInput, setMissingInput] = useState(false);
  const [operationNumberInput, setOperationNumberInput] = useState('');
  const [manuallyAccept, setManuallyAccept] = useState(false);
  const [waitingManuallyAccept, setWaitingManuallyAccept] = useState(false);
  const [waitingRefund, setWaitingRefund] = useState(false);
  const [waitingPay, setWaitingPay] = useState(false);
  const { user } = useAuth();
  const [waitingCancelTransaction, setWaitingCancelTransaction] =
    useState(false);

  const loading =
    waitingPay ||
    waitingRefund ||
    waitingCancelTransaction ||
    waitingManuallyAccept;

  const doRefund = async () => {
    setPosResponse(null);
    try {
      return await POS.refund(ticketData.operationNumber)
        .then((res) => {
          setPosResponse(res);
          if (res.responseCode === 0) {
            return [true, res];
          } else {
            refundSwal.fire({
              title: `Problema al anular (${res.responseCode})`,
              html: `La transacción no se pudo anular. ${res.responseMessage}`,
              icon: 'error',
            });
            return [false, {}];
          }
        })
        .catch((e) => {
          console.log(e);
          refundSwal.fire({
            title: 'Error con POS',
            html: 'La transacción no se pudo anular por error en conexión con POS Integrado',
            icon: 'error',
          });
          return [false, {}];
        });
    } catch (e) {
      console.log(e);
      refundSwal.fire({
        title: 'Error con POS',
        html: 'La transacción no se pudo anular por error en conexión con POS Integrado o su agente',
        icon: 'error',
      });
      return [false, {}];
    }
  };

  const handleSuccessRefund = async (posResponse) => {
    // Send request
    const formData = {
      transaction_id: ticketData.id,
      merchant_id: ticketData.merchantId,
      posResponse: posResponse ? JSON.stringify(posResponse) : '{}',
    };
    const endpoint = `transactions/${ticketData.id}/refund`;

    const success = await executeAPIAction(endpoint, 'PUT', formData);

    if (success) {
      refundSwal.fire({
        title: <p>Transaccion anulada</p>,
        html: <i>La transacción fue anulada correctamente</i>,
        icon: 'success',
      });
    } else {
      refundSwal.fire({
        title: <p>Transaccion anulada sin enviar</p>,
        html: (
          <i>
            La transacción fue anulada correctamente pero no se pudo envíar al
            sistema.
          </i>
        ),
        icon: 'error',
      });
    }
  };

  const refundTransaction = async (e) => {
    e.preventDefault();
    if (waitingRefund) return;

    setWaitingRefund(true);
    const [successRefund, posResponse] = await doRefund();
    if (successRefund) {
      // Send request
      await handleSuccessRefund(posResponse);
      refetch();
      setShowModal(false);
    }
    setWaitingRefund(false);
  };

  const handlePaySale = async (e) => {
    e.preventDefault();
    await executePaySaleOnPOS(
      setWaitingPay,
      refundSwal,
      posResponse,
      setPosResponse,
      ticketData,
      () => {},
      setShowModal
    );

    refetch();
  };

  const handleCancelTransaction = async (e) => {
    e.preventDefault();

    await executeExitPayment(
      null,
      setWaitingCancelTransaction,
      setShowModal,
      ticketData
    );

    refetch();
  };

  const handleManuallyAcceptTransactionOption = (e) => {
    e.preventDefault();
    setManuallyAccept(true);
  };

  const handleChangeOperationNumber = (e) => {
    const { value } = e.target;
    setOperationNumberInput(value);
    if (value === '') {
      setMissingInput(true);
    } else {
      setMissingInput(false);
    }
  };

  const handleReturnFromManuallyAccept = (e) => {
    e.preventDefault();
    setManuallyAccept(false);
  };

  const handleManuallyAcceptTransaction = async (e) => {
    e.preventDefault();
    if (missingInput) return;

    try {
      setWaitingManuallyAccept(true);
      // Send request
      const formData = {
        transaction_id: ticketData.id,
        merchant_id: ticketData.merchantId,
        posResponse: JSON.stringify({ type: 'manual', user: user.email }),
        operationNumber: operationNumberInput,
        status: 'success',
      };
      const endpoint = `transactions/${ticketData.id}`;

      await executeAPIAction(endpoint, 'PUT', formData);
      setWaitingManuallyAccept(false);

      refetch();
      setShowModal(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <ModalTemplate
      withoutClose
      closeOnOutsideClick={false}
      setShowModal={setShowModal}
      title="Gestión de pago"
    >
      <div className="w-96 p-4">
        <div className="w-full">
          <div className="p-1">
            <span className="font-bold">Número de ticket: </span>
            {ticketData.ticket}
          </div>
          <div className="p-1">
            <span className="font-bold">Número de operación: </span>
            {ticketData.operationNumber}
          </div>
          <div className="p-1">
            <span className="font-bold">Código de autorización: </span>
            {ticketData.authorizationCode}
          </div>
          <div className="p-1">
            <span className="font-bold">Estado: </span>
            {transactionStatusDictionary(ticketData.status)}
          </div>
          <div className="p-1">
            <span className="font-bold">Cajero: </span>
            {ticketData.userUsername} - {ticketData.userEmail}
          </div>
          <div className="p-1">
            <span className="font-bold">Fecha: </span>
            {dateConverter(ticketData.createdAt)}
          </div>
          <hr className="py-1" />
          <div id="products" className="payment-items-box p-1 w-full">
            {/* header */}
            <div className="p-1 grid grid-cols-3">
              <span className="font-bold">Item</span>
              <span className="font-bold">Cant.</span>
              <span className="font-bold">Precio</span>
            </div>
            {/* body */}
            {ticketData.direct_payment && (
              <div className="p-1 grid grid-cols-3">
                <span className="break-words">Venta directa</span>
                <span>1</span>
                <span>{ticketData.baseAmount}</span>
              </div>
            )}
            {!ticketData.direct_payment &&
              ticketData.transactionProducts.map((transactionProduct) => {
                return (
                  <div
                    key={transactionProduct.id}
                    className="p-1 grid grid-cols-3"
                  >
                    <span className="break-words">
                      {transactionProduct.product.name}
                    </span>
                    <span>{transactionProduct.quantity}</span>
                    <span>
                      {transactionProduct.product.price *
                        transactionProduct.quantity}
                    </span>
                  </div>
                );
              })}
          </div>
          <hr className="py-1" />
          <div id="Amounts" className="p-1 w-full">
            <div className="p-1 grid grid-cols-2">
              <span className="font-bold">Monto total:</span>
              <span>${ticketData.baseAmount}</span>
            </div>
            <div className="p-1 grid grid-cols-2">
              <span className="font-bold">Descuento:</span>
              <span>${ticketData.discount}</span>
            </div>
            <div className="p-1 grid grid-cols-2 text-xl">
              <span className="font-bold">Total:</span>
              <span>${ticketData.amount}</span>
            </div>
          </div>
          <hr className="py-1" />
        </div>

        <div className="w-full mt-3">
          {/* Manually accept */}
          {ticketData.status === 'in_progress' &&
            !manuallyAccept &&
            user.roles.filter((role) => ['super_admin', 'admin'].includes(role))
              .length > 0 && (
              <div className="group w-full">
                <button
                  disabled={loading || connected === null || !socketConnected}
                  type="button"
                  id="forceButton"
                  className="btn w-full btn-default waves-effect waves-light py-2 px-3 mb-2 col-span-3"
                  onClick={handleManuallyAcceptTransactionOption}
                >
                  Aceptar compra manualmente
                </button>
              </div>
            )}
          {ticketData.status === 'in_progress' &&
            manuallyAccept &&
            user.roles.filter((role) => ['super_admin', 'admin'].includes(role))
              .length > 0 && (
              <div className="group mb-2 w-full">
                <Input
                  id="search"
                  className="px-2 py-1 w-full"
                  placeholder="Agregar el número de operación del POS"
                  value={operationNumberInput}
                  onChange={handleChangeOperationNumber}
                />
                {missingInput && (
                  <p className="text-red-500">
                    Número de operación no puede estar vacío
                  </p>
                )}
              </div>
            )}
          {ticketData.status === 'in_progress' &&
            manuallyAccept &&
            user.roles.filter((role) => ['super_admin', 'admin'].includes(role))
              .length > 0 && (
              <div className="group w-full">
                <button
                  disabled={loading || connected === null || !socketConnected}
                  type="button"
                  id="forceButton"
                  className="btn w-full btn-success waves-effect waves-light py-2 px-3 mb-2 col-span-3"
                  onClick={handleManuallyAcceptTransaction}
                >
                  {waitingManuallyAccept ? (
                    <Spinner size={4} />
                  ) : (
                    'Aceptar compra manualmente'
                  )}
                </button>
              </div>
            )}
          {ticketData.status === 'in_progress' &&
            manuallyAccept &&
            user.roles.filter((role) => ['super_admin', 'admin'].includes(role))
              .length > 0 && (
              <div className="group mb-4 w-full">
                <button
                  disabled={loading || connected === null || !socketConnected}
                  type="button"
                  id="forceButton"
                  className="btn w-full btn-warning waves-effect waves-light py-2 px-3 mb-2 col-span-3"
                  onClick={handleReturnFromManuallyAccept}
                >
                  {waitingManuallyAccept ? <Spinner size={4} /> : 'Volver'}
                </button>
              </div>
            )}
          {ticketData.status === 'in_progress' && !manuallyAccept && (
            <div className="group w-full">
              <button
                disabled={loading || connected === null || !socketConnected}
                type="button"
                id="refundButton"
                className="btn w-full btn-success waves-effect waves-light py-2 px-3 mb-2 col-span-3"
                onClick={handlePaySale}
              >
                {waitingPay ? <Spinner size={4} /> : 'Reintentar compra'}
              </button>
              {(!connected || !socketConnected) && (
                <span
                  className="absolute hidden group-hover:flex ml-20
                              -mt-14 translate-y-full w-64 px-2 py-1
                              bg-gray-700 rounded-lg text-center text-white text-sm"
                >
                  No se puede continuar una compra sin la conexión al POS
                  Integrado
                </span>
              )}
            </div>
          )}
          {ticketData.status === 'in_progress' && !manuallyAccept && (
            <div className="group w-full">
              <button
                disabled={loading || connected === null || !socketConnected}
                type="button"
                id="refundButton"
                className="btn w-full btn-danger waves-effect waves-light py-2 px-3 mb-2 col-span-3"
                onClick={handleCancelTransaction}
              >
                {waitingCancelTransaction ? (
                  <Spinner size={4} />
                ) : (
                  'Cancelar compra'
                )}
              </button>
            </div>
          )}
          {ticketData.operationNumber !== null &&
            !manuallyAccept &&
            ticketData.status === 'success' && (
              <div className="group w-full">
                <button
                  disabled={loading || connected === null || !socketConnected}
                  type="button"
                  id="refundButton"
                  className="btn w-full btn-warning waves-effect waves-light py-2 px-3 mb-2 col-span-3"
                  onClick={refundTransaction}
                >
                  {waitingRefund ? <Spinner size={4} /> : 'Anular compra'}
                </button>
                {(!connected || !socketConnected) && (
                  <span
                    className="absolute hidden group-hover:flex ml-20
                              -mt-14 translate-y-full w-64 px-2 py-1
                              bg-gray-700 rounded-lg text-center text-white text-sm"
                  >
                    No se puede anular una compra sin la conexión al POS
                    Integrado o al agente
                  </span>
                )}
              </div>
            )}
          <button
            type="button"
            id="payButton"
            className="btn w-full btn-default waves-effect waves-light py-2 px-3 col-span-3"
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </div>
    </ModalTemplate>
  );
};

ModalTransaction.propTypes = {
  connected: PropType.string,
  socketConnected: PropType.bool,
  executePaySaleOnPOS: PropType.func,
  executeExitPayment: PropType.func,
  ticketData: PropType.object,
  setShowModal: PropType.func,
  refundSwal: PropType.any,
  refetch: PropType.func,
};

export default ModalTransaction;
