import PropTypes from 'prop-types';

// Components
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import SideBar from '../SideBar/SideBar';
import { useState } from 'react';

const TemplatePage = (props) => {
  const {
    children,
    view,
    setView,
    setShowConnectToPOSModal,
    setShowPOSActionsMenuModal,
    setShowCloseDayModal,
    setCloseModalType,
  } = { ...props };

  const [showSideBar, setShowSideBar] = useState(false);

  return (
    <main className={'flex flex-col h-screen w-full bg-slate-100'}>
      <SideBar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        view={view}
        setView={setView}
        setShowCloseDayModal={setShowCloseDayModal}
        setCloseModalType={setCloseModalType}
      />
      <Header
        setShowConnectToPOSModal={setShowConnectToPOSModal}
        setShowPOSActionsMenuModal={setShowPOSActionsMenuModal}
        setShowCloseDayModal={setShowCloseDayModal}
        setCloseModalType={setCloseModalType}
        setShowSideBar={setShowSideBar}
        view={view}
        setView={setView}
      />
      <div className={'flex-1 w-full pl-3 pr-3 h-auto'}>{children}</div>
      <Footer />
    </main>
  );
};

TemplatePage.propTypes = {
  children: PropTypes.array,
  view: PropTypes.string,
  setView: PropTypes.func,
  setShowConnectToPOSModal: PropTypes.func,
  setShowPOSActionsMenuModal: PropTypes.func,
  setShowCloseDayModal: PropTypes.func,
  setCloseModalType: PropTypes.func,
};

export default TemplatePage;
