import useDictionary from './useDictionary';

const errorsDictionary = (value) => {
  const dictionary = {
    'An inactive product with that sku already exists, try to reactivate it':
      'Ya existe un producto inactivo con ese sku, prueba reactivandolo',
    "There's an inactive category with that name, try reactivating it":
      'Ya existe una categoría inactiva con ese nombre, intenta reactivarla',
    "The products category is inactive. Try reactivating it before it's products.":
      'La categoría de este producto está inactiva. Intenta reactivarla primero.',
    'Product with that sku already exists': 'Ya tienen un producto con ese sku',
  };
  return useDictionary(value, dictionary);
};

export default errorsDictionary;
