import currencyFormat from '../../../utils/currencyFormat';
import dateConverter from '../../../utils/dateConverter';
import transactionStatusDictionary from '../../dictionaries/transactionStatusDictionary';

const parseTransaction = (transaction) => {
  const {
    id,
    amount,
    baseAmount,
    discount,
    merchantId,
    status,
    ticket,
    operationNumber,
    authorizationCode,
    created_at: createdAt,
    direct_payment,
    user,
    posResponse,
    transactionProducts,
  } = transaction;

  return {
    id,
    ticket,
    amount,
    parsedAmount: currencyFormat(amount),
    baseAmount,
    discount,
    merchantId,
    status,
    authorizationCode: authorizationCode || '-',
    operationNumber: operationNumber || '-',
    parsedStatus: transactionStatusDictionary(status),
    createdAt,
    direct_payment,
    parsedCreatedAt: dateConverter(createdAt),
    userUsername: user.username,
    userEmail: user.email,
    transactionProducts,
    posResponse,
  };
};

const parseTransactions = (apiData) => {
  const { data, summary, metadata } = apiData;
  const headers = [
    { attrName: 'ticket', name: 'N° Ticket' },
    { attrName: 'operationNumber', name: 'N° operación' },
    { attrName: 'parsedCreatedAt', name: 'Fecha' },
    { attrName: 'parsedStatus', name: 'Estado' },
    { attrName: 'parsedAmount', name: 'Monto' },
    { attrName: 'clickAction', name: 'Acciones' },
  ];
  const parsedData = {
    headers,
    parsedData: data.map((transaction) => parseTransaction(transaction)),
  };

  return {
    parsedData,
    summary,
    metadata: {
      current: parseInt(metadata.page || 0, 10),
      size: parseInt(metadata.size || 1, 10),
      count: Math.ceil(metadata.count / parseInt(metadata.size || 1, 10)),
    },
  };
};

export default parseTransactions;
