import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TableComponent from '../Shared/TableComponent';
import ModalTemplate from '../layouts/ModalTemplate';
import {
  faCheck,
  faPenToSquare,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import usePosApiFetch from '../../hooks/usePosAPIFetch';
import parseProductsRequest from '../../middlewares/parsers/Products/parseProductsRequest';
import Spinner from '../Shared/Spinner';
import usePosAPIAction from '../../hooks/usePosAPIAction';
import { useState } from 'react';
import errorsDictionary from '../../middlewares/dictionaries/errorsDictionary';

const ModalProducts = (props) => {
  const { setShowModal, setShowCreateProductModal, setSelectedProduct } = {
    ...props,
  };
  const [active, setActive] = useState(true);
  const {
    loading: loadingSubmit,
    executeAPIAction,
    error: actionError,
    setError: setActionError,
  } = usePosAPIAction();
  const { apiData, loading, error, refetch, setQueryParams } = usePosApiFetch(
    'products',
    parseProductsRequest
  );

  const handleEditClick = (e, row) => {
    e.preventDefault();
    setSelectedProduct(row);
    setShowCreateProductModal(true);
    setShowModal(false);
  };

  const handleDeleteClick = async (e, row) => {
    e.preventDefault();

    const endpoint = `products/${row.id}`;
    const method = 'DELETE';

    const success = await executeAPIAction(endpoint, method);
    if (success) {
      refetch();
      window.location.reload(false);
    }
  };

  const handleActivateClick = async (e, row) => {
    e.preventDefault();
    const endpoint = `products/${row.id}/activate`;
    const method = 'PUT';

    const success = await executeAPIAction(endpoint, method);
    if (success) {
      refetch();
      window.location.reload(false);
    }
  };

  const handleActiveClick = (e) => {
    e.preventDefault();
    setActionError(null);
    setQueryParams((values) => ({
      ...values,
      filters: {
        ...values.filters,
        active: !active,
      },
    }));
    setActive(!active);
  };

  const setAction = (row) => {
    return (
      <td key={`${row['sku']}-action`} className="p-2">
        <button
          type="button"
          className="btn btn-left btn-light btn-warning waves-effect waves-light py-1 px-2"
          id="edit"
          onClick={(e) => handleEditClick(e, row)}
        >
          <FontAwesomeIcon icon={faPenToSquare} />
        </button>
        {!row.active && (
          <button
            type="button"
            className="btn btn-right btn-light btn-success waves-effect waves-light py-1 px-2 group"
            title="This is my title"
            id="activate"
            onClick={(e) => handleActivateClick(e, row)}
          >
            <FontAwesomeIcon icon={faCheck} />
            <span
              className="absolute hidden group-hover:flex ml-4
              -mt-14 translate-y-full w-48 px-2 py-1
              bg-gray-700 rounded-lg text-center text-white text-sm"
            >
              Activar producto
            </span>
          </button>
        )}
        {row.active && (
          <button
            type="button"
            className="btn btn-right btn-light btn-danger waves-effect waves-light py-1 px-2 group"
            id="delete"
            onClick={(e) => handleDeleteClick(e, row)}
          >
            <FontAwesomeIcon icon={faXmark} />
            <span
              className="absolute hidden group-hover:flex ml-4
              -mt-14 translate-y-full w-48 px-2 py-1
              bg-gray-700 rounded-lg text-center text-white text-sm"
            >
              Desactivar producto
            </span>
          </button>
        )}
      </td>
    );
  };

  return (
    <ModalTemplate setShowModal={setShowModal} title={'Productos'}>
      {/*body*/}
      <div className="relative p-6 flex-auto w-full">
        {actionError?.response?.data?.statusCode === 400 && (
          <div className="m-auto my-3 w-96 text-sm flex flex-wrap text-center">
            <span className="text-red-500">
              {errorsDictionary(actionError?.response?.data?.message || '')}
            </span>
          </div>
        )}
        <div className="flex flex-row-reverse mb-2">
          <button
            type="button"
            id="searchBtn"
            className="btn btn-default waves-effect waves-light py-1 px-2"
            onClick={handleActiveClick}
          >
            {active ? 'Ver inactivos' : 'Ver activos'}
          </button>
        </div>
        {(loading || loadingSubmit) && <Spinner />}
        {!loading && !loadingSubmit && !error && (
          <TableComponent
            withActions
            pagination={apiData?.metadata || {}}
            setQueryParams={setQueryParams}
            paginationSizeOptions={[10]}
            headers={apiData.parsedData.headers}
            data={apiData.parsedData.parsedData}
            setAction={setAction}
            rowKey={'sku'}
          />
        )}
        {!loading && !loadingSubmit && error && (
          <div>Ocurrió un error, inténtalo más tarde</div>
        )}
      </div>
    </ModalTemplate>
  );
};

export default ModalProducts;
