import { useState } from 'react';
import Input from '../Shared/Input';
import ModalTemplate from '../layouts/ModalTemplate';
import useAuth from '../../hooks/useAuth';
import usePosAPIAction from '../../hooks/usePosAPIAction';
import Spinner from '../Shared/Spinner';
import errorsDictionary from '../../middlewares/dictionaries/errorsDictionary';

const generateDefaultValue = (category) => {
  if (!category) {
    return {
      name: '',
    };
  }
  return {
    name: category.name,
  };
};

const ModalCreateCategory = (props) => {
  const { category = null, setShowModal } = { ...props };
  const { user } = useAuth();
  const { loading, executeAPIAction, error: actionError } = usePosAPIAction();
  const [inputs, setInputs] = useState(generateDefaultValue(category));
  const disabled = !inputs.name;

  const generateTitle = () =>
    category ? 'Editar categoría' : 'Crear categoría';

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'text') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    const { name } = inputs;
    const formData = {
      name,
      merchant_id: user.merchantId,
    };
    const endpoint = `categories${category ? `/${category.id}` : ''}`;
    const method = category ? 'PUT' : 'POST';

    const success = await executeAPIAction(endpoint, method, formData);
    if (success) {
      setShowModal(false);
    }
  };

  return (
    <ModalTemplate setShowModal={setShowModal} title={generateTitle()}>
      {/*body*/}
      <div className="create-modal relative p-6 w-full flex-auto">
        {loading && <Spinner />}
        {!loading && (
          <form id="create-product" onSubmit={handleSubmit}>
            <div className="w-full my-3">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Nombre
              </label>
              <Input
                placeholder="Ingresa el nombre del producto..."
                value={inputs.name}
                name="name"
                id="name"
                onChange={handleChange}
              />
            </div>
            {actionError?.response?.data?.statusCode === 400 && (
              <div className="my-3 w-96 text-sm flex flex-wrap text-center">
                <span className="text-red-500">
                  {errorsDictionary(actionError?.response?.data?.message || '')}
                </span>
              </div>
            )}
            <div className="w-full my-3 flex">
              <button
                disabled={disabled}
                type="submit"
                className={`btn btn-light ${
                  disabled ? 'btn-inverse' : 'btn-primary'
                } waves-effect waves-light py-2 px-5 m-auto`}
              >
                Enviar
              </button>
            </div>
          </form>
        )}
      </div>
    </ModalTemplate>
  );
};

export default ModalCreateCategory;
