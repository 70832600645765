import { useState } from 'react';
import PropTypes from 'prop-types';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

// Components
import ModalTransaction from '../../components/Transactions/ModalTransaction';
import ModalGetSalesOfTheDay from '../../components/Pos/ModalGetSalesOfTheDay';
import Spinner from '../../components/Shared/Spinner';
import TransactionFilters from '../../components/Transactions/TransactionsFilters';
import TransactionsSummary from '../../components/Transactions/TransactionsSummary';
import TransactionsTable from '../../components/Transactions/TransactionsTable';

// Hooks
import usePosApiFetch from '../../hooks/usePosAPIFetch';
import usePOS from '../../hooks/usePOS';

// Middlewares
import parseTransactions from '../../middlewares/parsers/Transactions/parseTransactions';

const Transactions = (props) => {
  const { setShowCloseDayModal } = props;
  const today = new Date();
  const {
    connected,
    socketConnected,
    executePaySaleOnPOS,
    executeExitPayment,
  } = usePOS();
  const { apiData, loading, error, setQueryParams, refetch } = usePosApiFetch(
    'transactions',
    parseTransactions,
    {
      dateFrom: today.toISOString().split('T')[0],
      dateTo: today.toISOString().split('T')[0],
      status: 'all',
    }
  );
  const [filters, setFilters] = useState({
    dateFrom: today.toISOString().split('T')[0],
    dateTo: today.toISOString().split('T')[0],
    status: 'all',
  });
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [showPOSDailySalesModal, setShowPOSDailySalesModal] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const refundSwal = withReactContent(Swal);

  const handleFilter = (e) => {
    e.preventDefault();
    setQueryParams((values) => ({
      paginationInfo: {
        ...values.paginationInfo,
        page: 1,
      },
      filters: {
        ...values.filters,
        ...filters,
      },
    }));
  };

  const handleShowTransaction = (row) => {
    setTicketData(row);
    setShowTransactionModal(true);
  };

  return (
    <div className="h-full flex p-5">
      <div className="w-full h-full grid grid-rows-6 gap-3 p-4 bg-white rounded-lg">
        {showPOSDailySalesModal && (
          <ModalGetSalesOfTheDay
            setShowModal={setShowPOSDailySalesModal}
            setShowCloseDayModal={setShowCloseDayModal}
          />
        )}
        {showTransactionModal && (
          <ModalTransaction
            connected={connected}
            socketConnected={socketConnected}
            executePaySaleOnPOS={executePaySaleOnPOS}
            executeExitPayment={executeExitPayment}
            ticketData={ticketData}
            setShowModal={setShowTransactionModal}
            refundSwal={refundSwal}
            refetch={refetch}
          />
        )}
        <div className="row-span-1 border-b-2">
          <TransactionFilters
            connected={connected}
            filters={filters}
            setFilters={setFilters}
            handleFilter={handleFilter}
            setShowPosDailySales={setShowPOSDailySalesModal}
          />
        </div>
        <div className="row-span-5 grid grid-cols-4 gap-3">
          <div className="col-span-1">
            <TransactionsSummary
              data={apiData?.summary || {}}
              loading={loading}
            />
          </div>
          <div className="col-span-3">
            {loading && (
              <div className="flex w-full h-full">
                <div className="m-auto">
                  <Spinner />
                </div>
              </div>
            )}
            {!loading && !error && (
              <TransactionsTable
                data={apiData?.parsedData?.parsedData || []}
                headers={apiData?.parsedData?.headers || []}
                pagination={apiData?.metadata || {}}
                setQueryParams={setQueryParams}
                handleShowTransaction={handleShowTransaction}
              />
            )}
            {!loading && error && (
              <div className="flex w-full h-full">
                <div className="m-auto">Ocurrió un error</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

Transactions.propTypes = {
  setShowCloseDayModal: PropTypes.func,
};

export default Transactions;
