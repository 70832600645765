import { useState } from 'react';
import PropTypes from 'prop-types';

// hooks
import useAuth from '../../hooks/useAuth';
import usePosAPIAction from '../../hooks/usePosAPIAction';

// middlewares
import { rolesDict } from '../../middlewares/parsers/Users/parseUsers';
import errorsDictionary from '../../middlewares/dictionaries/errorsDictionary';

// components
import ModalTemplate from '../layouts/ModalTemplate';
import Spinner from '../Shared/Spinner';
import Select from '../Shared/Select';
import Input from '../Shared/Input';

const generateDefaultValue = (user) => {
  if (!user) {
    return {
      email: '',
      username: '',
      password: '',
      repeatedPassword: '',
      role: 'caja',
    };
  }
  return {
    email: user.email,
    username: user.username,
    password: '',
    repeatedPassword: '',
    role: user.roles[0],
  };
};

const ModalCreateUser = (props) => {
  const { userData = null, setShowModal, refetch } = props;
  const { user } = useAuth();
  const {
    loading: loadingSubmit,
    executeAPIAction,
    error: actionError,
  } = usePosAPIAction();

  const [inputs, setInputs] = useState(generateDefaultValue(userData));
  const roleOptions = Object.entries(rolesDict).map(([id, name]) => ({
    id,
    name,
  }));

  const disabled =
    !(
      inputs.email &&
      inputs.username &&
      inputs.password &&
      inputs.repeatedPassword &&
      inputs.role
    ) ||
    inputs.repeatedPassword !== inputs.password ||
    (inputs.password !== '' && inputs.password.length < 8);

  const generateTitle = () => (userData ? 'Editar usuario' : 'Crear usuario');

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === 'select-one') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (type === 'text') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (type === 'password') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
    if (type === 'number') {
      setInputs((values) => ({
        ...values,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async () => {
    const { email, username, password, repeatedPassword, role } = inputs;
    if (password !== repeatedPassword) return;

    const formData = {
      email,
      username,
      password,
      roles: [role],
      ext_merchant_cred: '',
      merchant_id: user.merchantId,
    };
    const endpoint = `users/create`;
    const method = userData ? 'PUT' : 'POST';

    const success = await executeAPIAction(endpoint, method, formData);
    if (success) {
      refetch();
      setShowModal(false);
    }
  };

  return (
    <ModalTemplate setShowModal={setShowModal} title={generateTitle()}>
      {/*body*/}
      <div className="create-modal relative p-6 w-full flex-auto">
        {loadingSubmit && <Spinner />}
        {!loadingSubmit && (
          <form id="create-user" onSubmit={handleSubmit}>
            <div className="w-full my-3">
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Email
              </label>
              <Input
                placeholder="Ingresa el email del usuario..."
                value={inputs.email}
                name="email"
                id="email"
                onChange={handleChange}
              />
            </div>
            <div className="w-full my-3">
              <label
                htmlFor="username"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Nombre de usuario
              </label>
              <Input
                placeholder="Ingresa el nombre de usuario..."
                value={inputs.username}
                name="username"
                id="username"
                onChange={handleChange}
              />
            </div>
            <div className="w-full my-3">
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Contraseña
              </label>
              <Input
                type="password"
                placeholder="Ingresa la contraseña..."
                value={inputs.password}
                name="password"
                id="password"
                onChange={handleChange}
              />
            </div>
            {inputs.password !== '' && inputs.password.length < 8 && (
              <div className="mt-1 mb-3 w-80 text-sm flex flex-wrap text-center">
                <span className="text-red-500">
                  La contraseña debe tener largo mayor o igual a 8
                </span>
              </div>
            )}
            <div className="w-full my-3">
              <label
                htmlFor="repeatedPassword"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Repetir contraseña
              </label>
              <Input
                type="password"
                placeholder="Ingresa la contraseña otra vez..."
                value={inputs.repeatedPassword}
                name="repeatedPassword"
                id="repeatedPassword"
                onChange={handleChange}
              />
            </div>
            {inputs.password !== inputs.repeatedPassword && (
              <div className="mt-1 mb-3 w-80 text-sm flex flex-wrap text-center">
                <span className="text-red-500">
                  Las contraseñas deben ser iguales
                </span>
              </div>
            )}
            <div className="w-full my-3">
              <label
                htmlFor="categories"
                className="block mb-2 text-sm font-semibold text-gray-900"
              >
                Rol
              </label>
              <Select
                id="role"
                name="role"
                onChange={handleChange}
                value={inputs.role}
                placeholder="Selecciona un rol"
                options={roleOptions}
              />
            </div>
            {actionError?.response?.data?.statusCode === 400 && (
              <div className="my-3 w-96 text-sm flex flex-wrap text-center">
                <span className="text-red-500">
                  {errorsDictionary(actionError?.response?.data?.message || '')}
                </span>
              </div>
            )}
            <div className="w-full my-3 flex">
              <button
                disabled={disabled}
                type="submit"
                className={`btn btn-light ${
                  disabled ? 'btn-inverse' : 'btn-primary'
                } waves-effect waves-light py-2 px-5 m-auto`}
              >
                Enviar
              </button>
            </div>
          </form>
        )}
      </div>
    </ModalTemplate>
  );
};

ModalCreateUser.propTypes = {
  userData: PropTypes.object,
  setShowModal: PropTypes.func,
  refetch: PropTypes.func,
};

export default ModalCreateUser;
